import { useEffect, useState } from 'react'
import { DropzoneDialog } from 'mui-file-dropzone'
import Button from '../Forms/Button/Button'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { uploadDataToUserStorage } from '../../../firebase'
import { useSnackbar } from 'notistack'
import StorageHelper from '../../../helpers/StorageHelper/StorageHelper'

const DropzoneArea = ({
  callback,
  buttonName,
  filesLimit = 1,
  name = filesLimit === 1 ? 'Wybierz plik' : 'Wybierz pliki',
  showPreviews = true,
  showPreviewsInDropzone = false,
  showFileNames = true,
  showFileNamesInPreview = true,
  useChipsForPreview = false,
  sizeFileLimit,
  urlUpload = false,
  ext = null,
  pathName,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const sizeFile = sizeFileLimit || 1000000
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [url, setUrl] = useState(urlUpload || '')
  const [files, setFiles] = useState(null)
  const handleClose = () => setOpen(false)
  const handleSave = (files) => {
    setIsLoading(true)
    setFiles(files)
    setOpen(false)
  }
  const handleOpen = () => setOpen(true)
  const handleExt = () => {
    if (ext === 'csv') return ['.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .txt']
    else if (ext === 'xml') return ['.xml', '.rss']
    else if (ext === 'json') return ['.json']
    else if (ext === 'images') return ['image/*']
    else return ['image/*', 'video/*', 'application/*', '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values']
  }
  const generateUrlWithoutUid = (nameWithUid) => {
    let onlyName = ''
    nameWithUid.split('_')?.map((el, index) => {
      if (index !== 0) {
        onlyName += el
      }
      return true
    })
    return onlyName
  }
  const generateUrlToShow = () => generateUrlWithoutUid(StorageHelper.getPathNameFromUrl(url, urlUpload))
  useEffect(() => {
    if (files) {
      uploadDataToUserStorage(files, pathName)
        .catch(() => enqueueSnackbar('Problem z załadowaniem pliku na storage.', { variant: 'error' }))
        .then((res) => {
          if (res) {
            setUrl(res)
            setIsLoading(false)
          }
        })
    }
  }, [files])
  useEffect(() => {
    if (callback && url?.length > 0) callback(url)
  }, [url, urlUpload])
  return (
		<Box
			sx={{
			  display: 'flex',
			  alignItems: 'flex-start',
			  justifyContent: 'center',
			  flexDirection: 'column',
			  width: '100%',
			}}
      className="dropzone"
		>
			<Button sx={{ maxWidth: '320px', marginTop: '10px' }} onClick={handleOpen.bind(this)}>
				{buttonName || name}
			</Button>
			<Typography
				sx={{
				  marginTop: '10px',
				  fontSize: '13px',
				  display: 'inline-grid',
				  color: '#3b3636',
				  marginBottom: url ? '5px' : '25px',
				}}
			>
				{ ext && <span>Możliwe rozszerzenie pliku do załadowania: .{ext}.&nbsp;</span> }
        Maksymalna wielkość plików: {sizeFile / 1000000}MB.
			</Typography>
			{
				url?.length > 0 && ext === 'images' && (
					<img src={url} style={{ height: '50px', marginBottom: '10px' }} alt="imagePreview"/>
				 )
			}
      {
        isLoading
? <CircularProgress sx={{ color: '#2e7d32' }} />
: (
          <>
            {
              url?.length > 0 && (
                <Box sx={{ marginBottom: '30px', color: theme.palette.toasts.success }}>
                  { generateUrlToShow() }
                </Box>
              )
            }
          </>
        )
      }
			<DropzoneDialog
				open={open}
				onSave={handleSave.bind(this)}
				acceptedFiles={handleExt()}
				filesLimit={filesLimit}
				dropzoneText="Przeciągnij lub wybierz plik"
				previewText="Lista plików"
				submitButtonText="Zatwierdź dodanie"
				getFileLimitExceedMessage={() => `Przekroczono maksymalną wielkość pliku. Dozwolone jest tylko ${sizeFile}.`}
				getFileAddedMessage={(fileName) => `Pomyślnie dodano plik ${fileName}.`}
				getFileRemovedMessage={(fileName) => `Pomyślnie usunięto plik ${fileName}.`}
				getDropRejectMessage={() => `Przekroczono maksymalną wielkość pliku. Dozwolone jest tylko ${sizeFile / 1000000}MB.`}
				dropzoneClass="dropzoneComponent"
				dropzoneParagraphClass="dropzoneText"
				showPreviews={showPreviews}
				showPreviewsInDropzone={showPreviewsInDropzone}
				showFileNames={showFileNames}
				showFileNamesInPreview={showFileNamesInPreview}
				useChipsForPreview={useChipsForPreview}
				maxFileSize={sizeFile}
				onClose={handleClose.bind(this)}
			/>
		</Box>
  )
}

export default DropzoneArea
