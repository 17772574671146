import firebase from 'firebase/compat/app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, setDoc, updateDoc } from 'firebase/firestore'
import LocalStorageHelper from './helpers/LocalStorageHelper/LocalStorageHelper'
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import StorageHelper from './helpers/StorageHelper/StorageHelper'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  storageBucketImport: process.env.REACT_APP_STORAGE_BUCKET_IMPORT,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

// eslint-disable-next-line jest/require-hook
const app = firebase.initializeApp(firebaseConfig)

const auth = getAuth()
const db = getFirestore()

export const signOutFirebase = () => signOut(auth).then((res) => res).catch((error) => console.error(error.code, error.message))

export const getAllElementsByName = async (name) => await getAllDataFromPath(name).then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data).forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      tempRes.push(tempEl)
    })
    return tempRes
  }
})

export const createOrUpdatePartnersForm = async (data, id = null) => await updateOrCreateDocument('partners', id, data).then((res) => res)

export const createOrUpdateCommodity = async (data, id = null) => await updateOrCreateDocument('commodity', id, data).then((res) => res)

export const createOrUpdateJobs = async (data, id = null) => await updateOrCreateDocument('jobs', id, data).then((res) => res)

export const getElementById = async (id, name) => await getAllDataFromPath(name, id).then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      if (el?.id === id) {
        const tempEl = {}
        Object.keys(el?.data).forEach((element) => {
          tempEl[element] = el?.data[element]
        })
        tempEl.id = el?.id
        tempRes.push(tempEl)
      }
    })
    return tempRes
  }
})

export const createUser = (data) => {
  return createUserWithEmailAndPassword(auth, data.email, data.password).then(async (userCredential) => {
    const user = await userCredential?.user
    if (user) {
      sendEmailVerified(user)
      return addDocument(data, user).then(res => res)
    }
  }).catch((error) => console.error(error.code, error.message))
}

export const changeDataUser = async (email, data) => {
  const docRef = doc(db, 'users', email)
  await updateDoc(docRef, {
    name: data?.name,
    phone: data?.phone,
    street: data?.street,
    apartment: data?.apartment,
    house: data?.house,
    defaultLang: data?.defaultLang
  })
  return true
}

export const sendEmailVerified = (userCredential) => {
  sendEmailVerification(userCredential).then(() => LocalStorageHelper.set('emailForSignIn', true)).catch((error) => console.error(error.code, error.message))
}

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email).then(() => true).catch((error) => console.error(error.code, error.message))
}

export const updateOrCreateDocument = async (path, pathSegment, data) => {
  if (pathSegment) await setDoc(doc(db, path, pathSegment), data)
  else await addDoc(collection(db, path), data)
  return data
}

export const getDataFromPath = async (path, pathSegments) => {
  const docSnap = await getDoc(doc(db, path, pathSegments))
  if (docSnap.exists()) return docSnap.data()
  return {}
}

export const addDocument = async (data, user) => {
  const sendData = {
    uid: user?.uid,
    email: data?.email || user?.email || '',
    phone: data?.phone || user?.phoneNumber || '',
    name: `${data?.firstName} ${data?.secondName}` || user?.name || '',
    role: 'user'
  }
  return await updateOrCreateDocument('users', data.email || user.email, sendData).then((res) => {
    if (res) {
      LocalStorageHelper.set('user', sendData)
      return sendData
    }
  })
}

export const updateUsers = async (data) => await updateOrCreateDocument('users', data?.email, data).then(res => res)

export const getAllDataFromPath = async (path) => {
  const allDocs = []
  const querySnapshot = await getDocs(collection(db, path))
  await querySnapshot.forEach((doc) => allDocs.push({ id: doc.id, data: doc.data() }))
  return allDocs
}

export const refreshToken = () => auth?.currentUser?.getIdToken()

export const loginUser = (email, password) => {
  const auth = getAuth()
  return signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
    return userCredential?.user
  }).catch((error) => console.error(error.code, error.message))
}

export const uploadDataToUserStorage = (files, pathName = null) => {
  return uploadDataToStorage(files, `${pathName || `/users/${LocalStorageHelper.get('user')?.uid || (+new Date()).toString(36).slice(-8)}`}`, null).then(
    (res) => {
      return res
    }
  )
}

export const uploadDataToStorage = (files, path, addMoreFiles = false, lastUrl) => {
  const file = !addMoreFiles && files[0]
  const storage = getStorage(
    app,
    path === '/services' ? firebaseConfig.storageBucket : firebaseConfig.storageBucketImport
  )
  const storageRef = ref(storage, `${path}/${(+new Date()).toString(36).slice(-8)}_${file.name}`)
  const nameLastFile = StorageHelper.getPathNameFromUrl(lastUrl, 'users', 'small')
  const storageLastRef = ref(storage, nameLastFile)
  return uploadBytes(storageRef, file, file?.type).then((snapshot) => {
    if (snapshot) {
      if (lastUrl?.length > 0 && lastUrl.search('/services')) deleteObject(storageLastRef)
      return getDownloadURL(snapshot?.ref).then((downloadURL) => downloadURL || '')
    } else return null
  })
}

export const deleteFromPath = async (id, name) => await deleteDoc(doc(db, name, id))

export default firebase
