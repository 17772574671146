import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

export const style = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 80px)',
    margin: '0 auto',
    position: 'relative',
    top: '-50px',
  },
  small: {
    fontSize: '16px',
    background: '#F2F3F5',
    color: '#231E41',
    padding: '2px 5px',
    maxWidth: '200px',
    marginBottom: '15px',
    textAlign: 'center',
  },
  title: {
    fontSize: BreakpointsHelper.isMobile() ? '54px' : '72px',
    letterSpacing: '-3px',
    lineHeight: '100%',
    fontWeight: '600',
    marginBottom: '30px'
  },
  desc: {
    fontSize: '18px',
    color: '#6F6F87',
    marginBottom: '30px'
  },
  buttons: {
    display: 'flex',
  },
  button: {
    height: '48px',
    marginRight: '20px'
  },
  ummmy1: {
    position: 'absolute',
    background: 'white',
    borderRadius: '5px',
    padding: '10px 15px',
    left: '35%',
    top: '100px',
    zIndex: 2,
    filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.3))',
  },
  ummmy2: {
    position: 'absolute',
    background: 'white',
    borderRadius: '5px',
    padding: '10px 15px',
    left: '50%',
    top: '350px',
    zIndex: 2,
    filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.3))',
  },
  circle1: {
    width: '25px',
    height: '25px',
    background: '#074F1F',
    borderRadius: '50%',
    position: 'absolute',
    top: '10px',
    left: '60px',
    zIndex: 3,
    filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))',
  },
  circle2: {
    width: '50px',
    height: '50px',
    background: '#074F1F',
    borderRadius: '50%',
    position: 'absolute',
    top: '350px',
    left: '150px',
    zIndex: 3,
    filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))',
  },
  circle3: {
    width: '50px',
    height: '50px',
    border: '20px solid white',
    borderRadius: '50%',
    position: 'absolute',
    top: '200px',
    left: '100%',
    zIndex: 3,
    filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
  },
  circle4: {
    width: '25px',
    height: '25px',
    border: '15px solid white',
    borderRadius: '50%',
    position: 'absolute',
    top: '220px',
    left: '10%',
    zIndex: 3,
    filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
  },
  plate1: {
    width: '360px',
    height: '360px',
    position: 'absolute',
    left: '-50px',
    top: '-50px',
  },
  plate2: {
    width: '280px',
    height: '280px',
    position: 'absolute',
    left: 'calc(50% + 50px)',
  },
  plate3: {
    width: '380px',
    height: '380px',
    position: 'absolute',
    top: '220px',
    left: 'calc(25%)',
  },
  box: {
    background: 'white',
    filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
  }
}
