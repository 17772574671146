import { Box, Grid, Typography } from '@mui/material'
import SizeWindow from '../SizeWindow/SizeWindow'
import { ReactComponent as Drinks } from '../../../assets/images/categoryFoods/3.svg'
import { ReactComponent as MainFood } from '../../../assets/images/categoryFoods/2.svg'
import { ReactComponent as Desserts } from '../../../assets/images/categoryFoods/4.svg'
import { ReactComponent as Starters } from '../../../assets/images/categoryFoods/1.svg'

const WhatEat = () => {
  const style = {
    root: {
      paddingTop: '100px',
    },
    img: {
      width: '100%',
      fill: 'white',
      height: '50px',
    },
    title: {
      color: '#231E41',
      fontSize: '40px',
      fontWeight: '600',
      lineHeight: '110%',
      letterSpacing: '-2px',
      marginBottom: '30px',
      marginTop: '10px',
    },
    textJustify: {
      fontSize: '18px',
      color: '#6F6F87',
      maxWidth: '720px',
      textAlign: 'justify',
    },
    text: {
      fontSize: '18px',
      color: '#6F6F87',
      maxWidth: '720px',
    },
    imgBox: {
      background: '#074F1F',
      padding: '20px',
      height: '30px',
      display: 'flex',
      borderRadius: '5px',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '30px',
    },
    box: {
      display: 'flex',
      background: '#F9FAFC',
      padding: '20px',
    },
    titleBox: {
      fontWeight: '600',
      fontSize: '24px',
    }
  }
  return (
    <SizeWindow>
      <Box sx={style.root}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography sx={style.title}>
              Na co masz dzisiaj ochotę?
            </Typography>
            <Typography sx={style.textJustify}>
              Nasi partnerzy są mistrzami w przygotowywaniu niemal wszystkiego, czego możesz zapragnąć. Niezależnie od tego, czy szukasz wyjątkowej kuchni, przygód na świeżym powietrzu czy luksusowych doświadczeń, nasi eksperci z różnych dziedzin są gotowi spełnić Twoje najskrytsze marzenia.
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Box sx={style.box}>
                  <Box sx={style.imgBox}>
                    <Drinks style={style.img} className="svgWhite" />
                  </Box>
                  <Box>
                    <Typography sx={style.titleBox}>Drinki</Typography>
                    <Typography sx={style.text}>
                      Nasi wyjątkowi partnerzy barmani tworzą niezapomniane drinki, które zaskoczą Twoje podniebienie i oczarują Twoje zmysły.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={style.box}>
                  <Box sx={style.imgBox}>
                    <MainFood style={style.img} className="svgWhite" />
                  </Box>
                  <Box>
                    <Typography sx={style.titleBox}>Główne dania</Typography>
                    <Typography sx={style.text}>
                      Główne dania, które są prawdziwymi dziełami sztuki kulinarnej, łącząc doskonały smak, unikalne połączenia składników i estetyczne wykończenie.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={style.box}>
                  <Box sx={style.imgBox}>
                    <Desserts style={style.img} className="svgWhite" />
                  </Box>
                  <Box>
                    <Typography sx={style.titleBox}>Desery</Typography>
                    <Typography sx={style.text}>
                      Nasi mistrzowie deserów tworzą wyjątkowe słodkie kompozycje, które rozpieszczą Twoje podniebienie i dostarczą niezapomnianych doznań smakowych.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={style.box}>
                  <Box sx={style.imgBox}>
                    <Starters style={style.img} className="svgWhite" />
                  </Box>
                  <Box>
                    <Typography sx={style.titleBox}>Sałatki</Typography>
                    <Typography sx={style.text}>
                      Nasi utalentowani kucharze przygotowują sałatki, które nie tylko zachwycają świeżością i kreatywnymi połączeniami składników.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </SizeWindow>
  )
}

export default WhatEat
