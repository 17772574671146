import NamePage from '../../components/molecules/NamePage/NamePage'
import { Box, Grid, Typography } from '@mui/material'
import imageAbout1 from '../../assets/images/negocjacje.jpg'
import imageAbout2 from '../../assets/images/restaurant.png'
import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'

const PricesPage = () => {
    const style = {
        root: {
            marginTop: '200px',
            marginBottom: '200px',
        },
        img: {
            width: '100%',
            minHeight: '500px',
            objectFit: 'cover',
        },
        title: {
            color: '#231E41',
            fontSize: '40px',
            fontWeight: '600',
            lineHeight: '110%',
            letterSpacing: '-2px',
            marginBottom: '30px',
        },
        textJustify: {
            fontSize: '18px',
            color: '#6F6F87',
            marginBottom: '10px',
            textAlign: 'justify',
        },
        text: {
            fontSize: '18px',
            color: '#6F6F87',
            marginBottom: '10px'
        },
        circle1: {
            width: '25px',
            height: '25px',
            background: '#074F1F',
            borderRadius: '50%',
            position: 'absolute',
            top: '0',
            left: '-20px',
            zIndex: 3,
            filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))',
        },
        circle2: {
            width: '50px',
            height: '50px',
            background: '#074F1F',
            borderRadius: '50%',
            position: 'absolute',
            bottom: '-20px',
            left: '50px',
            zIndex: 3,
            filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))',
        },
        circle3: {
            width: '80px',
            height: '80px',
            border: '30px solid white',
            borderRadius: '50%',
            position: 'absolute',
            bottom: '-50px',
            right: '-50px',
            zIndex: 3,
            filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
        },
        circle4: {
            width: '50px',
            height: '50px',
            border: '20px solid white',
            borderRadius: '50%',
            position: 'absolute',
            bottom: '50px',
            left: '20px',
            zIndex: 3,
            filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
        },
    }
  return (
      <NamePage name="Negocjacje cenowe">
          <SizeWindow>
              <Box sx={style.root}>
                  <Grid container spacing={12}>
                      <Grid item md={6} xs={12}>
                          <img src={imageAbout1} alt="about us" style={style.img} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                          <Typography sx={style.title}>
                              Podstawa współpracy
                          </Typography>
                          <Typography sx={style.textJustify}>
                              Zapraszamy do współpracy jako nasz partner handlowy w branży gastronomicznej! Jesteśmy firmą <b>Gram w gastro</b>, posiadającą ponad 100 lokali w naszym koszyku zakupowym. Dzięki tej skalowalności, jesteśmy w stanie zaoferować Państwu korzystne warunki handlowe, uwzględniające nasze duże zamówienia. Naszym celem jest nawiązanie długoterminowych relacji z solidnymi dostawcami, którzy dostarczą wysokiej jakości produkty do naszych lokali. Liczymy na owocną współpracę, która przyniesie wzajemne korzyści. Dołączcie do grona naszych partnerów i dajcie nam szansę udowodnienia, jak wartościowym partnerem możemy być dla Was. Czekamy na Wasze propozycje i jesteśmy gotowi do dalszych negocjacji w celu ustalenia szczegółów współpracy.
                          </Typography>
                      </Grid>
                  </Grid>
              </Box>
              <Box sx={style.root}>
                  <Grid container spacing={12}>
                      <Grid item md={6} xs={12}>
                          <Typography sx={style.title}>
                              Posiadasz własną restaurację?
                          </Typography>
                          <Typography sx={style.textJustify}>
                              Jesteśmy otwarci nie tylko na współpracę z dostawcami, ale również z restauracjami, które poszukują konkurencyjnych cen od naszych dostawców. Dlatego zachęcamy restauratorów do dołączenia do naszego zespołu Gram w gastro. Dzięki naszemu rozległemu koszykowi zakupowemu, możemy negocjować atrakcyjne warunki handlowe z naszymi dostawcami, co pozwoli Państwu osiągnąć znaczące oszczędności i zwiększyć konkurencyjność swojego biznesu. Razem tworzymy silną sieć gastronomiczną, która korzysta z wzajemnych korzyści. Jeśli szukają Państwo możliwości uzyskania konkurencyjnych cen od sprawdzonych dostawców, serdecznie zapraszamy do naszego grona partnerów. Otwartych jesteśmy na rozmowy i negocjacje, aby dostosować się do Państwa potrzeb i wymagań.
                          </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                          <img src={imageAbout2} alt="about us" style={style.img} />
                      </Grid>
                  </Grid>
              </Box>
          </SizeWindow>
      </NamePage>
  )
}

export default PricesPage
