import { useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { changeDataUser } from '../../firebase'
import { useSnackbar } from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Tabs from '../../components/molecules/Tabs/Tabs'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'

const ProfilePage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const user = LocalStorageHelper.get('user')
  const { enqueueSnackbar } = useSnackbar()
  const style = {
    root: {
      display: 'flex',
      marginTop: 10,
      padding: BreakpointsHelper.isMobile() ? '15px' : '15px 50px',
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '500px',
    },
  }
  const formData = {
    elements: [
      {
        name: 'name',
        type: 'text',
        label: t.globals.inputs.name.label,
        disabled: true,
        value: user?.name || '',
        validationType: 'string',
      },
      {
        name: 'email',
        type: 'email',
        label: t.globals.inputs.email.label,
        disabled: true,
        value: user?.email || '',
        validationType: 'string',
      },
      {
        name: 'password',
        type: 'password',
        label: t.globals.inputs.password.label,
        helperText: t.globals.inputs.password.helperText,
        validationType: 'string',
      },
      {
        name: 'phone',
        type: 'phone',
        disabled: true,
        label: t.globals.inputs.phone.label,
        value: user?.phone || '',
        validationType: 'string',
      },
      {
        type: 'button',
        value: t.globals.save,
      }
    ],
  }
  const handleChangeDataSubmit = (e) => {
    const data = {
      name: e.name,
      phone: e.phone,
      street: e.street,
      apartment: e.apartment,
      house: e.house,
      defaultLang: e.defaultLang,
    }
    const email = e.email
    changeDataUser(email, data).then((res) => {
      if (res) enqueueSnackbar('Zmieniono dane poprawnie.', { variant: 'success' })
      else enqueueSnackbar('Nastąpił problem ze zmianą danych.', { variant: 'error' })
    })
  }
  const handleChangeData = () => {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Typography
                sx={{
                  fontSize: '16px',
                }}
              >
                {t.profilePage.descriptionData}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <FormGenerator data={formData} submit={(e) => handleChangeDataSubmit(e)} {...props}/>
            </Grid>
          </Grid>
        </>
      )
  }
  const dataTabs = [
    {
      name: t.profilePage.changeData,
      value: () => handleChangeData(),
    },
  ]
  useEffect(() => {
    const pathname = window.location.pathname.search('deleteAccount')
    if (pathname > 0) {
      enqueueSnackbar(t.profilePage.toasts.success, { variant: 'success' })
    }
    return () => clearTimeout()
  }, [])
  return (
    <>
      <div style={style.root}>
        <Tabs data={dataTabs}/>
      </div>
    </>
  )
}

export default ProfilePage
