import { Helmet } from 'react-helmet'
import Slider from '../../components/atoms/Slider/Slider'
import AboutUs from '../../components/atoms/AboutUs/AboutUs'
import Footer from '../../components/molecules/Footer/Footer'
import Partners from '../../components/molecules/Partners/Partners'
import Cookers from '../../components/molecules/Cookers/Cookers'
import WhatEat from '../../components/molecules/WhatEat/WhatEat'

const HomePage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Gram w gastro</title>
        <meta name="keywords" content=""/>
        <meta name="description" content=""/>
        <meta name="subject" content=""/>
        <meta name="copyright" content=""/>
        <meta name="language" content="PL"/>
        <meta name="robots" content="index,follow"/>
        <meta name="author" content="Jan Szymański, kontakt@your-site.pl"/>
        <meta name="designer" content="Jan Szymański, kontakt@your-site.pl"/>
        <meta name="copyright" content="Jan Szymański, kontakt@your-site.pl"/>
        <meta name="owner" content="OSACORP"/>
        <meta name="url" content=""/>
        <meta name="identifier-URL" content=""/>
        <meta name="category" content="Kampery"/>
        <meta name="distribution" content="Global"/>
        <meta name="rating" content="General"/>
        <meta name="revisit-after" content="7 days"/>
        <meta httpEquiv="Expires" content="0"/>
        <meta httpEquiv="Pragma" content="no-cache"/>
        <meta httpEquiv="Cache-Control" content="no-cache"/>
        <meta name="og:title" content=""/>
        <meta name="og:type" content="website"/>
        <meta name="og:url" content=""/>
        <meta name="og:site_name" content=""/>
        <meta name="og:description" content=""/>
        <meta name="fb:page_id" content=""/>
        <meta name="og:email" content=""/>
        <meta name="og:phone_number" content=""/>
        <meta name="og:locality" content="Koszalin"/>
        <meta name="og:region" content="Zachodniopomorskie"/>
        <meta name="og:postal-code" content=""/>
        <meta name="og:country-name" content="Polska"/>
        <meta property="og:type" content="website"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta content="yes" name="apple-touch-fullscreen"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
        <meta name="format-detection" content="telephone=no"/>
      </Helmet>
      <Slider />
      <WhatEat />
      <AboutUs />
      <Footer />
    </>
  )
}

export default HomePage
