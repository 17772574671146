import { Grid, Typography } from '@mui/material'
import NamePage from '../../components/molecules/NamePage/NamePage'
import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { getAllDataFromPath } from '../../firebase'

const PostPage = () => {
  const [data, setData] = useState(null)
  const history = useHistory()
  const idPost = history?.location?.pathname?.split('/')?.length === 3 ? history?.location?.pathname?.split('/')[2] : null
  useEffect(() => {
    if (idPost) {
      getAllDataFromPath('posts').then((res) => {
        if (res) {
          res?.forEach((el) => {
            if (el.id === idPost) {
              setData(el.data)
            }
          })
        }
      })
    }
  }, [idPost])
  return (
    <SizeWindow>
      <NamePage name={data?.title.toUpperCase()}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <img src={data?.image} style={{ width: '100%', height: '300px', objectFit: 'cover', marginBottom: '50px' }} alt={data?.title} />
            <Typography sx={{ fontWeight: '600' }}>Autor: Patrycja Bujak</Typography>
            <Typography className="texts" dangerouslySetInnerHTML={{ __html: data?.description }}/>
          </Grid>
        </Grid>
      </NamePage>
    </SizeWindow>
  )
}

export default PostPage
