import BreakpointsHelper from './BreakpointsHelper'
import BreakpointsEnum from '../../enums/BreakpointsEnum/BreakpointsEnum'

export default {
  getBreakpoint: (breakpoint, upOrDown) => {
    switch (breakpoint) {
      case 'xxl':
        if (upOrDown === 'down') return BreakpointsEnum.xxlMin
        else return BreakpointsEnum.xxlMax
      case 'xl':
        if (upOrDown === 'down') return BreakpointsEnum.xlMin
        else return BreakpointsEnum.xlMax
      case 'lg':
        if (upOrDown === 'down') return BreakpointsEnum.lgMin
        else return BreakpointsEnum.lgMax
      case 'md':
        if (upOrDown === 'down') return BreakpointsEnum.mdMin
        else return BreakpointsEnum.mdMax
      case 'sm':
        if (upOrDown === 'down') return BreakpointsEnum.smMin
        else return BreakpointsEnum.smMax
      case 'xs':
        if (upOrDown === 'down') return BreakpointsEnum.xsMin
        else return BreakpointsEnum.xsMax
      default:
        return null
    }
  },
  checkSizeWindow: (breakpoint, upOrDown = 'down') => {
    const widthWindow = document.body.clientWidth
    if (upOrDown === 'down') {
      return (
        BreakpointsHelper.getBreakpoint(breakpoint, upOrDown) >= widthWindow
      )
    } else {
      return (
        BreakpointsHelper.getBreakpoint(breakpoint, upOrDown) < widthWindow
      )
    }
  },
  isMobile: () => BreakpointsHelper.checkSizeWindow('md', 'down'),
}
