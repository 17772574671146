import ReactDOM from 'react-dom'
import App from './App'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ToastProvider from './components/organisms/ToastProvider/ToastProvider'

const MOUNT_NODE = document.getElementById('root')

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#074F1F',
      black: '#000000',
      white: '#ffffff',
    },
    secondary: {
      main: '#66bb6a',
      second: '#ab47bc',
      grey: '#B5B5B5',
      greyLight: '#EBEBEB',
      greyDark: '#767373'
    },
    toasts: {
      success: '#2e7d32',
      warning: '#ed6c02',
      error: '#d32f2f',
      info: '#0288d1'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    useNextVariants: true
  },
  config: {
    maxToasts: 5,
  }
})

// eslint-disable-next-line jest/require-hook
ReactDOM.render(
  <ToastProvider theme={theme}>
    <ThemeProvider theme={theme}>
        <App/>
    </ThemeProvider>
  </ToastProvider>,
  MOUNT_NODE
)
