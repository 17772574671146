export default {
  get: (cname) => {
    const nameTemp = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1)
      if (c.indexOf(nameTemp) === 0) return c.substring(nameTemp.length, c.length)
    }
    return ''
  },
  set: (key, name) => {
    document.cookie = `${key}=${name}`
  }
}
