import { Box, Button, Grid, Typography } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { useEffect, useState } from 'react'
import { deleteFromPath, getAllElementsByName, updateOrCreateDocument } from '../../firebase'
import { useSnackbar } from 'notistack'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { useHistory } from 'react-router'

const JobsListPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const user = LocalStorageHelper.get('user')
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    box: {
      padding: '10px 20px',
      boxShadow: '2px 2px 20px 1px rgba(0,0,0,0.15)',
      marginBottom: '30px',
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    description: {
      fontSize: '16px',
      lineHeight: '110%',
      fontWeight: '300',
      marginTop: '5px',
    },
    element: {
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid rgba(7,79,31)',
      background: '#ffeaea',
    },
    elementOK: {
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid rgba(7,79,31)',
      background: '#eaffea',
    },
  }
  const deleteJob = (id) => {
    deleteFromPath(id, 'jobs').then(() => {
      getAllElementsByName('jobs').then((res) => {
        if (res) {
          enqueueSnackbar('Oferta pracy została poprawnie usunięta.', { variant: 'success' })
          setData(res)
        }
      })
    })
  }
  useEffect(() => {
    getAllElementsByName('jobs').then((res) => {
      if (res) {
        if (user?.companies?.length > 0) {
          const temp = []
          res?.forEach(element => {
            user?.companies?.forEach(el => {
              if (element.company === el) {
                temp.push(element)
              }
            })
          })
          setData(temp)
        } else {
          setData(res)
        }
      }
    })
  }, [])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={user?.companies?.length > 0 ? 8 : 12} xs={12}>
                <Typography sx={style.title}>
                  Oferty pracy
                </Typography>
                <Typography sx={style.description}>
                  Możliwość zobaczenia listy ofert pracy, ich edycja oraz dodanie nowej oferty pracy na wybranego partnera.
                </Typography>
              </Grid>
              {
                user?.companies?.length > 0 && (
                  <Grid item md={4} xs={12}>
                   <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end', height: '100%' }}>
                     <Button
                       sx={{ marginTop: '10px', marginRight: '10px', marginBottom: '10px' }}
                       variant="contained"
                       onClick={() => history.push('/jobNeed')}
                     >
                       Dodaj potrzebę stanowiska
                     </Button>
                   </Box>
                  </Grid>
                )
              }
              <Grid item md={12} xs={12}>
                {
                  data?.length
                    ? data?.map((el, index) => {
                      return (
                        <Box key={index} sx={style.elementOK}>
                          <Typography sx={{ marginBottom: '5px' }}><b>ID Firmy:</b> {el.id}, <b>Stanowisko:</b> {el.position}</Typography>
                          <Typography sx={{ marginBottom: '5px' }}><b>Rodzaj umowy:</b> {el.docs}, <b>Miasto:</b> {el.city}</Typography>
                          <Typography sx={{ marginBottom: '5x' }}>
                            <b>Opis: </b><span dangerouslySetInnerHTML={{ __html: el.description }} />
                          </Typography>
                          <Typography sx={{ marginBottom: '5px' }}>
                            <b>Oczekiwania: </b><span dangerouslySetInnerHTML={{ __html: el.expectations }} />
                          </Typography>
                          <Typography sx={{ marginBottom: '5px' }}>
                            <b>Wymagania: </b><span dangerouslySetInnerHTML={{ __html: el.requirements }} />
                          </Typography>
                          {
                            (user?.role === 'admin' || user?.role === 'superadmin') && (
                              <Button
                                sx={{ marginTop: '10px', marginRight: '10px', marginBottom: '10px', background: '#cb0000' }}
                                variant="contained"
                                onClick={() => deleteJob(el.id)}
                              >
                                Usuń ofertę
                              </Button>
                            )
                          }
                        </Box>
                      )
                    })
                    : (
                      <Typography>
                        Brak ofert pracy
                      </Typography>
                    )
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default JobsListPage
