import React, { useEffect, useRef, useState } from 'react'
import Input from '../../atoms/Forms/Input/Input'
import Button from '../../atoms/Forms/Button/Button'
import InputPassword from '../../atoms/Forms/InputPassword/InputPassword'
import { Formik } from 'formik'
import * as yup from 'yup'
import ValidationHelper from '../../../helpers/ValidationHelper/ValidationHelper'
import PropTypes from 'prop-types'
import DropzoneArea from '../../atoms/DropzoneArea/DropzoneArea'
import {
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  TextField,
  useTheme,
  Autocomplete,
  MenuItem,
  Select,
  InputLabel,
  FormControl, CircularProgress
} from '@mui/material'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import Checkboxes from '../../atoms/Forms/Checkboxes/Checkboxes'
import InputMask from 'react-input-mask'
import Editor from '../../atoms/Forms/Editor/Editor'
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import Typography from '@mui/material/Typography'

const FormGenerator = (props) => {
  const { data, submit } = props
  const [isLoading, setIsLoading] = useState(true)
  const [editor, setEditor] = useState('')
  const [multiSelect, setMultiSelect] = useState([])
  const formRef = useRef()
  const theme = useTheme()
  const style = {
    elements: {
      padding: '10px 0',
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '0 auto',
      textAlign: 'left',
    },
    elementsError: {
      padding: '20px 0',
      maxWidth: '320px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flex: 1,
      border: '1px solid red',
      justifyContent: 'center',
      width: '100%',
      margin: '0 auto',
      textAlign: 'left'
    },
    helperText: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontWeight: 400,
      fontSize: '.75rem',
      letterSpacing: '.03333em',
      marginTop: '3px',
      marginRight: '14px',
      marginBottom: 0,
      marginLeft: '14px'
    }
  }
  const yepSchema = data?.elements?.reduce(ValidationHelper.createYupSchema, {})
  const validationSchema = yup.object().shape(yepSchema)
  const initialValues = {}
  useEffect(() => {
    if (data && initialValues) {
      data?.elements?.map((el) => {
        if (el.type !== 'button') {
          initialValues[el.name] = el?.value || el?.defaultValue || ''
        }
        return true
      })
      setIsLoading(false)
    }
  }, [data])
  useEffect(() => {
    if (editor) {
      formRef.current.values.description = editor
    }
  }, [editor])
  return (
    <>
      {
        data && initialValues && (
          <Formik
            innerRef={formRef}
            onSubmit={(e) => submit(e)}
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnMount
          >
            {({ values, touched, errors, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
              <>
                {
                  isLoading
                    ? <div style={style.root}><CircularProgress /></div>
                    : (
                    <form autoComplete="off" style={{ width: '100%' }}>
                      <div style={style.elements}>
                        {
                          data?.elements?.map((el, index) => {
                            if ((el.superuser && LocalStorageHelper.get('user')?.role === 'superuser') || !el.superuser) {
                              switch (el.type) {
                                case 'button':
                                  return (
                                    <Button
                                      sx={{ marginTop: '10px', width: '100%' }}
                                      key={index}
                                      type="button"
                                      onClick={() => handleSubmit(values)}
                                      disabled={Object.values(errors).length > 0}
                                    >
                                      {el?.value}
                                    </Button>
                                  )
                                case 'checkbox':
                                  return (
                                    <FormControlLabel
                                      key={index}
                                      label={el.label}
                                      sx={{
                                        marginBottom: '15px',
                                      }}
                                      control={
                                        <Checkbox
                                          defaultChecked={el?.defaultValue}
                                          name={el.name}
                                          onChange={(e) => setFieldValue(el.name, e?.target?.checked)}
                                          onBlur={(e) => setFieldValue(el.name, e?.target?.checked)}
                                          onKeyUp={(e) => setFieldValue(el.name, e?.target?.checked)}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: theme.palette.primary.main,
                                            '&.Mui-checked': {
                                              color: theme.palette.primary.main,
                                            },
                                          }}
                                        />
                                      }
                                    />
                                  )
                                case 'password':
                                  return (
                                    <InputPassword
                                      key={index}
                                      name={el?.name}
                                      label={el?.label}
                                      required={el?.required}
                                      value={values[el.name] || ''}
                                      disabled={el?.disabled}
                                      onBlur={handleBlur}
                                      onKeyUp={handleBlur}
                                      onChange={handleChange}
                                      helperText={el?.helperText}
                                      defaultValue={el?.defaultValue}
                                      error={touched[el?.name] && errors[el?.name]}
                                    />
                                  )
                                case 'editor':
                                  // eslint-disable-next-line no-case-declarations
                                  const blocksFromHtml = htmlToDraft(el?.value || '')
                                  // eslint-disable-next-line no-case-declarations
                                  const { contentBlocks, entityMap } = blocksFromHtml
                                  // eslint-disable-next-line no-case-declarations
                                  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
                                  // eslint-disable-next-line no-case-declarations
                                  const editorState = EditorState.createWithContent(contentState)
                                  return (
                                    <Editor
                                      key={index}
                                      defaultValue={editorState}
                                      value={editor}
                                      label={el?.label}
                                      onChange={(e) => {
                                        setEditor(draftToHtml(convertToRaw(e?.getCurrentContent())))
                                        setFieldValue(el?.name, draftToHtml(convertToRaw(e?.getCurrentContent())))
                                      }}
                                    />
                                  )
                                case 'phone':
                                  return (
                                    <InputMask
                                      key={index}
                                      mask="999 999 999"
                                      value={values[el?.name] || initialValues[el?.name] || ''}
                                      disabled={el?.disabled}
                                      maskChar=" "
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onKeyUp={handleBlur}
                                    >
                                      {
                                        () => (
                                          <TextField
                                            key={index}
                                            sx={{ marginBottom: '10px', width: '100%' }}
                                            name={el?.name}
                                            type={el?.type}
                                            disabled={el?.disabled}
                                            label={`${el?.label} *`}
                                            value={values[el?.name]}
                                            error={touched[el?.name] && errors[el.name]}
                                          />
                                        )
                                      }
                                    </InputMask>
                                  )
                                case 'date':
                                  return (
                                    <Input
                                      key={index}
                                      name={el.name}
                                      type="date"
                                      label={el.label}
                                      value={values[el.name]}
                                      helperText={el.helperText}
                                      disabled={el.disabled}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onKeyUp={handleBlur}
                                      required={el?.required}
                                      defaultValue={el?.defaultValue}
                                      error={touched[el.name] && errors[el.name]}
                                    />
                                  )
                                case 'textarea':
                                  return (
                                    <TextareaAutosize
                                      key={index}
                                      name={el?.name}
                                      type={el?.type}
                                      placeholder={el?.label + ' *'}
                                      value={values[el.name] || ''}
                                      disabled={el?.disabled}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onKeyUp={handleBlur}
                                      defaultValue={el?.defaultValue}
                                      error={touched[el.name] && errors[el.name]}
                                      style={{
                                        marginTop: '5px',
                                        marginBottom: '15px',
                                        width: 'calc(100% - 28px)',
                                        animationDuration: '10ms',
                                        padding: '16.5px 14px',
                                        height: '10em',
                                        fontSize: '16px',
                                        borderRadius: '4px',
                                      }}
                                    />
                                  )
                                case 'autocomplete':
                                  return (
                                    <Autocomplete
                                      key={index}
                                      sx={{ marginBottom: '10px', width: '100%' }}
                                      freeSolo
                                      multiple={el?.multiple}
                                      options={el?.options?.map((option) => option?.label)}
                                      onChange={(e, value) => {
                                        setFieldValue(el?.name, value)
                                      }}
                                      aria-required={el.required}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name={el?.name}
                                          value={values[el?.name]}
                                          label={el?.label}
                                        />
                                      )}
                                    />
                                  )
                                case 'hour':
                                  return (
                                    <TextField
                                      key={index}
                                      type="time"
                                      name={el?.name}
                                      label={el?.label}
                                      value={values[el.name] || ''}
                                      helperText={el?.helperText}
                                      disabled={el?.disabled}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onKeyUp={handleBlur}
                                      defaultValue={el?.defaultValue}
                                      error={touched[el.name] && errors[el.name]}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300,
                                      }}
                                      sx={{
                                        marginTop: '5px',
                                        marginBottom: '15px',
                                      }}
                                    />
                                  )
                                case 'select':
                                  return (
                                    <FormControl
                                      key={index}
                                      sx={{
                                        margin: '0',
                                        width: '100%',
                                        height: '56px',
                                        marginBottom: '20px'
                                      }}
                                    >
                                      <InputLabel>{el.label}</InputLabel>
                                      <Select
                                        defaultValue={el.defaultValue}
                                        value={values[el.name] || el.value || ''}
                                        label={el.label}
                                        name={el.name}
                                        sx={{
                                          height: '56px'
                                        }}
                                        onChange={(e) => {
                                          setFieldValue(el.name, e?.target?.value)
                                          if (el.onChange) el.onChange(e)
                                        }}
                                      >
                                        {
                                          el?.placeholder && (
                                            <MenuItem disabled value="">
                                              <em>{el.placeholder}</em>
                                            </MenuItem>
                                          )
                                        }
                                        {
                                          el?.items && el.items.map((menuItem) => (
                                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                              {menuItem.name}
                                            </MenuItem>
                                          ))
                                        }
                                      </Select>
                                    </FormControl>
                                  )
                                case 'files':
                                  return (
                                    <DropzoneArea
                                      key={index}
                                      name={el?.name}
                                      ext={el?.ext}
                                      buttonName={el?.buttonName}
                                      prefix={el?.prefix}
                                      pathName={el?.pathName}
                                      urlUpload={el?.urlUpload}
                                      sizeFileLimit={el?.sizeFileLimit}
                                      callback={(e) => setFieldValue(el?.name, e)}
                                    />
                                  )
                                case 'multiSelect':
                                  return (
                                    <FormControl
                                      key={index}
                                      sx={{
                                        margin: '0',
                                        width: '100%',
                                      }}
                                    >
                                      <InputLabel>{el.label}</InputLabel>
                                      <Select
                                        defaultValue={el.defaultValue}
                                        value={multiSelect}
                                        label={el.label}
                                        name={el.name}
                                        multiple
                                        onChange={(e) => {
                                          const { target: { value } } = e
                                          setFieldValue(el.name, value)
                                          setMultiSelect(typeof value === 'string' ? value.split(',') : value)
                                          if (el.onChange) el.onChange(e)
                                        }}
                                      >
                                        {
                                          el?.items && el.items.map((menuItem) => (
                                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                              {menuItem.name}
                                            </MenuItem>
                                          ))
                                        }
                                      </Select>
                                      <Typography sx={{ marginTop: '5px', fontSize: '14px', marginLeft: '20px', color: 'black' }}>
                                        {el?.helperText}
                                      </Typography>
                                    </FormControl>
                                  )
                                case 'checkboxes':
                                  return (
                                    <Checkboxes
                                      keyIndex={index}
                                      label={el?.label}
                                      values={el?.values}
                                      setFieldValue={setFieldValue}
                                    />
                                  )
                                default:
                                  return (
                                    <Input
                                      key={index}
                                      name={el.name}
                                      type={el.type}
                                      label={el.label}
                                      value={values[el.name] || ''}
                                      helperText={el.helperText}
                                      disabled={el.disabled}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onKeyUp={handleBlur}
                                      required={el?.required}
                                      defaultValue={el?.defaultValue}
                                      error={touched[el.name] && errors[el.name]}
                                    />
                                  )
                              }
                            }
                            return true
                          })
                        }
                      </div>
                    </form>
                  )}
              </>
            )}
          </Formik>
        )
      }
    </>
  )
}

FormGenerator.propTypes = {
  data: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
}

export default FormGenerator
