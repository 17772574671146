import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
		<DialogTitle sx={{ m: 0, p: 2, color: 'black', opacity: 1 }} {...other}>
			{children}
			{onClose
			  ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
					  position: 'absolute',
					  right: 8,
					  top: 8,
					  color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			    )
			  : null}
		</DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

const CustomizedDialogs = ({ content, open, title, buttons, onClose, small = false, noPadding }) => {
  const [openDialog, setOpenDialog] = useState(open)
  const handleClose = () => {
    setOpenDialog(false)
    if (onClose) onClose()
  }
  return (
		<>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby={title}
				open={openDialog}
				fullWidth={!small}
				className="dialog"
			>
				<BootstrapDialogTitle id={title} onClose={handleClose}>
					{title}
				</BootstrapDialogTitle>
				<DialogContent sx={{ padding: noPadding && '0 !important' }} dividers>
					{content}
				</DialogContent>
				{
					buttons && (
						<DialogActions>
							{
								buttons?.map((button, index) => {
								  return (
										<Button
											key={index}
											variant="contained"
											onClick={() => typeof buttons === 'object' ? button?.link() : window.open(button.link, '_blank')}
										>
											{button.name}
										</Button>
								  )
								})
							}
						</DialogActions>
					)
				}
			</BootstrapDialog>
		</>
  )
}

export default CustomizedDialogs
