import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import SizeWindow from '../SizeWindow/SizeWindow'

const Footer = () => {
  const t = LocalStorageHelper.get('translation')
  const style = {
    root: {
      display: 'flex',
      alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
      justifyContent: 'center',
      flexDirection: BreakpointsHelper.isMobile() && 'column',
      height: '80px',
      padding: '10px 0',
      width: '100%',
      borderTop: '1px solid #f3f3f3',
    },
  }
  return (
    <Box sx={style.root}>
      <SizeWindow>
        <Typography>
          @ {new Date().getFullYear()} wszelkie prawa zastrzeżone przez Gram w gastro.
          Strona stworzona przez <a href="" target="_blank" rel="noreferrer">Gram w gastro</a>.
          Wszelkie informacje dostępne pod:<br/>
          <a href="" target="_blank" rel="noreferrer">{t?.homePage?.footer.desc2}</a> lub <a href="" target="_blank" rel="noreferrer">{t?.homePage?.footer.desc3}</a>
        </Typography>
      </SizeWindow>
    </Box>
  )
}

export default Footer
