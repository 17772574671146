import { Box, Grid, Typography } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useEffect, useState } from 'react'
import { createOrUpdateJobs, getAllElementsByName, getElementById } from '../../firebase'
import { useSnackbar } from 'notistack'
import PositionHelper from '../../helpers/PositionHelper/PositionHelper'

const JobAddPage = (props) => {
  const idNeedJob = props?.location?.location?.pathname?.split('/')[2]
  const [dataNeed, setDataNeed] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [formData, setFormData] = useState(null)
  const [companiesItems, setCompaniesItems] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    box: {
      padding: '10px 20px',
      boxShadow: '2px 2px 20px 1px rgba(0,0,0,0.15)',
      marginBottom: '30px',
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    description: {
      fontSize: '16px',
      lineHeight: '110%',
      fontWeight: '300',
      marginTop: '5px',
    },
  }
  const handleData = (e) => {
    createOrUpdateJobs(e).then((res) => {
      if (res) {
        enqueueSnackbar('Oferta pracy została poprawnie dodana.', { variant: 'success' })
      }
    })
  }
  useEffect(() => {
    if (companiesItems) {
      const tempFormData = {
        elements: [
          {
            type: 'autocomplete',
            name: 'company',
            label: 'Wybierz firmę',
            value: idNeedJob && dataNeed ? dataNeed?.company : '',
            options: companiesItems,
            validationType: 'string',
          },
          {
            type: 'select',
            name: 'position',
            label: 'Stanowisko',
            value: idNeedJob && dataNeed ? dataNeed?.position : '',
            items: PositionHelper.getAll(),
            validationType: 'string',
          },
          {
            type: 'select',
            items: [
              { value: 'Koszalin', name: 'Koszalin' },
              { value: 'Mielno', name: 'Mielno' },
              { value: 'Jarosławiec', name: 'Jarosławiec' },
            ],
            name: 'city',
            label: 'Miasto',
            validationType: 'string',
          },
          {
            type: 'select',
            name: 'docs',
            items: [{ value: 'Umowa zlecenie', name: 'Umowa zlecenie' }, { value: 'Umowa o pracę', name: 'Umowa o pracę' }, { value: 'Umowa o dzieło', name: 'Umowa o dzieło' }],
            label: 'Typ umowy',
            validationType: 'string',
          },
          {
            type: 'editor',
            name: 'description',
            label: 'Opis',
            required: false,
            validationType: 'string',
          },
          {
            type: 'autocomplete',
            name: 'requirements',
            options: [
              { label: 'Umiejętność pracy w zespole', value: 'Umiejętność pracy w zespole' },
              { label: 'Chęć do nauki nowych umiejętności', value: 'Chęć do nauki nowych umiejętności' },
              { label: 'Kultura osobista i umiejętność rozwiązywania problemów', value: 'Kultura osobista i umiejętność rozwiązywania problemów' },
              { label: 'Prawo jazdy kat. B', value: 'Prawo jazdy kat. B' },
              { label: 'Chęci i zaangażowanie', value: 'Chęci i zaangażowanie' },
              { label: 'Dbałość o dobro firmy', value: 'Dbałość o dobro firmy' },
              { label: 'Wykonywanie powierzonych zadań', value: 'Wykonywanie powierzonych zadań' },
              { label: 'Dyspozycyjność', value: 'Dyspozycyjność' },
              { label: 'Miła aparycja', value: 'Miła aparycja' },
              { label: 'Chęć do pracy i rozwijania swoich umiejętności', value: 'Chęć do pracy i rozwijania swoich umiejętności' },
              { label: 'Dobra organizacja pracy i dbałość o szczegóły', value: 'Dobra organizacja pracy i dbałość o szczegóły' },
            ],
            multiple: true,
            required: false,
            label: 'Wymagania',
            validationType: 'array',
          },
          {
            type: 'autocomplete',
            name: 'expectations',
            options: [
              { label: 'Umiejętność pracy w zespole', value: 'Umiejętność pracy w zespole' },
              { label: 'Chęć do nauki nowych umiejętności', value: 'Chęć do nauki nowych umiejętności' },
              { label: 'Kultura osobista i umiejętność rozwiązywania problemów', value: 'Kultura osobista i umiejętność rozwiązywania problemów' },
              { label: 'Prawo jazdy kat. B', value: 'Prawo jazdy kat. B' },
              { label: 'Chęci i zaangażowanie', value: 'Chęci i zaangażowanie' },
              { label: 'Dbałość o dobro firmy', value: 'Dbałość o dobro firmy' },
              { label: 'Wykonywanie powierzonych zadań', value: 'Wykonywanie powierzonych zadań' },
              { label: 'Dyspozycyjność', value: 'Dyspozycyjność' },
              { label: 'Miła aparycja', value: 'Miła aparycja' },
              { label: 'Chęć do pracy i rozwijania swoich umiejętności', value: 'Chęć do pracy i rozwijania swoich umiejętności' },
              { label: 'Dobra organizacja pracy i dbałość o szczegóły', value: 'Dobra organizacja pracy i dbałość o szczegóły' },
            ],
            multiple: true,
            required: false,
            label: 'Oczekiwania',
            validationType: 'array',
          },
          {
            type: 'autocomplete',
            name: 'offer',
            options: [
              { label: 'Szkolenia wstępne i regularne szkolenia dla pracowników', value: 'Szkolenia wstępne i regularne szkolenia dla pracowników' },
              { label: 'Elastyczny grafik pracy', value: 'Elastyczny grafik pracy' },
              { label: 'Atrakcyjne wynagrodzenie', value: 'Atrakcyjne wynagrodzenie' },
              { label: 'Wynagrodzenie adekwatne do nabytych umiejętności', value: 'Wynagrodzenie adekwatne do nabytych umiejętności' },
              { label: 'Możliwość zdobycia doświadczenia w branży gastronomicznej', value: 'Możliwość zdobycia doświadczenia w branży gastronomicznej' },
              { label: 'Przyjazną atmosferę pracy i wspaniałych klientów', value: 'Przyjazną atmosferę pracy i wspaniałych klientów' },
              { label: 'Możliwość rozwoju zawodowego', value: 'Możliwość rozwoju zawodowego' },
              { label: 'Wyżywienie i zakwaterowanie', value: 'Wyżywienie i zakwaterowanie' },
              { label: 'Świetną atmosferę', value: 'Świetną atmosferę' },
              { label: 'Systemy motywacyjne', value: 'Systemy motywacyjne' },
              { label: 'Wynagrodzenie wypłacane zawsze na czas', value: 'Wynagrodzenie wypłacane zawsze na czas' },
            ],
            multiple: true,
            label: 'Oferujemy',
            required: false,
            validationType: 'array',
          },
          {
            type: 'number',
            name: 'bottomForks',
            label: 'Widełki dolne',
            validationType: 'string',
            value: idNeedJob && dataNeed ? dataNeed?.bottomForks : '',
            required: false,
          },
          {
            type: 'number',
            name: 'topForks',
            label: 'Widełki górne',
            validationType: 'string',
            value: idNeedJob && dataNeed ? dataNeed?.upperForks : '',
            required: false,
          },
        ]
      }
      tempFormData.elements.push({
        type: 'button',
        value: 'Zapisz dane',
      })
      setFormData(tempFormData)
    }
  }, [companiesItems, idNeedJob, dataNeed])
  useEffect(() => {
    getAllElementsByName('partners').then((res) => {
      if (res) {
        const tempItems = []
        res?.forEach(el => {
          if (el.inGramWGastro) {
            tempItems.push({ value: el.company, label: el.company })
          }
        })
        setCompaniesItems(tempItems)
      }
    })
  }, [])
  useEffect(() => {
    if (idNeedJob) {
      getElementById(idNeedJob, 'jobsNeed').then((res) => {
        if (res) {
          setDataNeed(res[0])
        }
      })
    }
  }, [idNeedJob])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={style.title}>
                  Dodanie oferty pracy
                </Typography>
                <Typography sx={style.description}>
                  Możliwość dodanie nowej oferty pracy dla istniejącego zaakceptowanego partnera.
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                {
                  (formData?.elements?.length > 0 && companiesItems)
                  ? (
                    <FormGenerator data={formData} submit={(e) => handleData(e)} />
                  )
                  : (
                    <Typography>
                      Brak dostępnych firm, dla których można dodać ofertę pracy.
                    </Typography>
                  )
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default JobAddPage
