import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { getAllDataFromPath, updateOrCreateDocument } from '../../firebase'
import { useSnackbar } from 'notistack'

const SupplierListPage = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [data, setData] = useState(null)
    const style = {
        root: {
            width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
            padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
        },
        box: {
            padding: '10px 20px',
            boxShadow: '2px 2px 20px 1px rgba(0,0,0,0.15)',
            marginBottom: '30px',
        },
        title: {
            fontSize: '24px',
            fontWeight: '600',
            textTransform: 'uppercase',
        },
        description: {
            fontSize: '16px',
            lineHeight: '110%',
            fontWeight: '300',
            marginTop: '5px',
        },
        element: {
            background: '#f3f3f3',
            padding: '10px 20px',
        }
    }
    const dataForm = {
        elements: [
            {
                name: 'name',
                label: 'Nazwa dostawcy',
                validationType: 'string',
            },
            {
                type: 'button',
                value: 'Dodaj dostawcę',
            }
        ]
    }
    const addSupplier = (e) => {
        updateOrCreateDocument('suppliers', null, e).then(() => {
            getSuppliers()
            enqueueSnackbar('Dodano poprawnie dostawcę.', { variant: 'success' })
        })
    }
    const getSuppliers = () => {
        getAllDataFromPath('suppliers').then((res) => {
            if (res) {
                setData(res)
            }
        })
    }
    useEffect(() => {
        getSuppliers()
    }, [])
    return (
        <div style={style.root}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box sx={style.box}>
                        <Grid container spacing={4}>
                            <Grid item md={12}>
                                <Typography sx={style.title}>
                                    Dostawcy
                                </Typography>
                                <Typography sx={style.description}>
                                    Możliwość dodania dostawcy oraz podłączenie pod niego jakie rodzaje towarów dostarcza i w jakiej cenie.
                                </Typography>
                                <Box sx={{ maxWidth: '320px' }}>
                                    <FormGenerator data={dataForm} submit={(e) => addSupplier(e)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ marginTop: '20px' }}>
                                    <Grid container spacing={2}>
                                        {
                                            data?.map((el, index) => {
                                                const element = el.data
                                                return (
                                                    <Grid key={index} item xs={12}>
                                                        <Box sx={style.element}>
                                                            <Typography>{element.name}</Typography>
                                                        </Box>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default SupplierListPage
