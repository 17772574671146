import { Grid } from '@mui/material'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import mockupCar from '../../assets/images/mockupCar.jpg'
import { createOrUpdatePartnersForm } from '../../firebase'
import { useSnackbar } from 'notistack'
import NamePage from '../../components/molecules/NamePage/NamePage'

const PartnersPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const style = {
    img: {
      width: '100%',
      marginBottom: '10px',
      marginTop: '15px',
    },
  }
  const formData = {
    elements: [
      {
        name: 'company',
        type: 'text',
        label: 'Nazwa firmy',
        validationType: 'string',
      },
      {
        name: 'website',
        type: 'text',
        label: 'Strona internetowa',
        validationType: 'string',
      },
      {
        name: 'logo',
        type: 'files',
        pathName: '/partners',
        sizeFileLimit: 100000000,
        buttonName: 'Wgraj logo',
        validationType: 'string',
      },
      {
        name: 'name',
        type: 'text',
        label: 'Osoba do kontaktu',
        validationType: 'string',
      },
      {
        name: 'email',
        type: 'email',
        label: 'Email',
        validationType: 'string',
      },
      {
        name: 'phone',
        type: 'text',
        label: 'Telefon',
        validationType: 'string',
      },
      {
        type: 'button',
        value: 'Wyślij dane',
      },
    ]
  }
  const handleData = (e) => {
    createOrUpdatePartnersForm(e).then((res) => {
      if (res) enqueueSnackbar('Wysłano dane poprawnie, skontaktujemy się najszybciej jak to możliwe.', { variant: 'success' })
      else enqueueSnackbar('Problem z wysłaniem danych.', { variant: 'warning' })
    }).catch(() => enqueueSnackbar('Problem z połączeniem z serwerem.', { variant: 'error' }))
  }
  return (
      <NamePage name="Zostań partnerem">
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <FormGenerator data={formData} submit={(e) => handleData(e)} />
          </Grid>
          <Grid item md={6} xs={12}>
            <img src={mockupCar} alt="car" style={style.img} />
          </Grid>
        </Grid>
      </NamePage>
  )
}

export default PartnersPage
