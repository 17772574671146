import { Box, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

const MagicLoader = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hide, setHide] = useState(false)
  const [progress, setProgress] = useState(10)
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
      position: 'relative',
      top: '-90px',
    }
  }
  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 1000)
  }, [])
  return (
    <>
      {
        isLoading
        ? (
          <Box sx={style.root}>
            <CircularProgress />
          </Box>
        )
        : props.children
      }
    </>
  )
}

export default MagicLoader
