import NamePage from '../../components/molecules/NamePage/NamePage'
import { Box, Grid, Typography } from '@mui/material'
import imageAbout0 from '../../assets/images/royalprofit-onas-1.png'
import imageAbout1 from '../../assets/images/pexels-photo-5554870.jpeg-1.jpg'
import imageAbout2 from '../../assets/images/1-1.jpg'
import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'

const BookkeepingPage = () => {
  const style = {
    root: {
      marginTop: '200px',
      marginBottom: '200px'
    },
    img: {
      width: '100%',
      minHeight: '500px',
      objectFit: 'cover'
    },
    title: {
      color: '#231E41',
      fontSize: '40px',
      fontWeight: '600',
      lineHeight: '110%',
      letterSpacing: '-2px',
      marginBottom: '30px'
    },
    textJustify: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px',
      textAlign: 'justify'
    },
    text: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px'
    },
    circle1: {
      width: '25px',
      height: '25px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      top: '0',
      left: '-20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))'
    },
    circle2: {
      width: '50px',
      height: '50px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-20px',
      left: '50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))'
    },
    circle3: {
      width: '80px',
      height: '80px',
      border: '30px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-50px',
      right: '-50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))'
    },
    circle4: {
      width: '50px',
      height: '50px',
      border: '20px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '50px',
      left: '20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))'
    }
  }
  return (
    <NamePage name="Księgowość">
      <SizeWindow>
        <Box sx={style.root}>
          <Grid container spacing={12}>
            <Grid item md={6} xs={12}>
              <img src={imageAbout0} alt="about us" style={style.img}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={style.title}>
                Profesjonalna Księgowość dla Gastronomii: Royal Profit z Koszalina
              </Typography>
              <Typography sx={style.textJustify}>
                Szukasz profesjonalnej obsługi księgowej dla swojej firmy gastronomicznej? Mamy zaszczyt przedstawić
                naszego partnera, Royal Profit Biuro Rachunkowe z Koszalina, które oferuje kompleksowe usługi księgowe
                dla firm małych, średnich i dużych.
              </Typography>
              <Typography sx={style.textJustify}>
                Royal Profit to doświadczone biuro rachunkowe, które specjalizuje się w obszarze księgowości i obsługi
                podatkowej. Ich zaangażowanie i profesjonalizm w każdym projekcie są niezaprzeczalne, a ich celem jest
                zapewnienie klientom pełnego wsparcia.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={style.root}>
          <Grid container spacing={12}>
            <Grid item md={6} xs={12}>
              <Typography sx={style.title}>
                Co wyróżnia Royal Profit Biuro Rachunkowe?
              </Typography>
              <Typography sx={style.textJustify}>
                <ul>
                  <li>
                    Partnerstwo oparte na wzajemnym zrozumieniu: Royal Profit dąży do nawiązania partnerskich relacji z
                    klientami, gdzie pełne wsparcie i zrozumienie są kluczowe.
                  </li>
                  <li>
                    Rozwiązywanie trudnych wyzwań: Nie boją się trudnych sytuacji i zawsze starają się znaleźć szybkie i
                    skuteczne rozwiązania.
                  </li>
                  <li>
                    Ubezpieczenie od odpowiedzialności cywilnej: Biuro rachunkowe posiada ubezpieczenie, co stanowi
                    dodatkową gwarancję bezpieczeństwa dla klientów.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <img src={imageAbout2} alt="about us" style={style.img}/>
            </Grid>
          </Grid>
        </Box>
        <Box sx={style.root}>
          <Grid container spacing={12}>
            <Grid item md={6} xs={12}>
              <img src={imageAbout1} alt="about us" style={style.img}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={style.title}>
                Księgowość bez Zmartwień: Royal Profit – Twój Partner w Gastronomii
              </Typography>
              <Typography sx={style.textJustify}>
                Dzięki usługom Royal Profit Biuro Rachunkowe, możesz pozbyć się wszelkich zmartwień związanych z
                księgowością w Koszalinie. Zaufaj im i skorzystaj z ich ekspertyzy, aby skoncentrować się na rozwoju
                swojego biznesu, wiedząc, że Twoje sprawy finansowe są w najlepszych rękach.
              </Typography>
              <Typography sx={style.textJustify}>
                Jeśli jesteś częścią Grupy gastronomicznej Gram w gastro i szukasz niezawodnego partnera księgowego, to
                Royal Profit Biuro Rachunkowe jest idealnym wyborem. Skontaktuj się z nimi już dziś, aby rozpocząć
                owocną współpracę.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </SizeWindow>
    </NamePage>
  )
}

export default BookkeepingPage
