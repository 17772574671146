import { Box, Button, Grid, Typography } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { useEffect, useState } from 'react'
import { getAllDataFromPath, updateOrCreateDocument } from '../../firebase'
import { useHistory } from 'react-router'

const JobsNeedListPage = () => {
  const history = useHistory()
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    element: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid rgba(7,79,31)',
      background: '#ffeaea',
    },
    elementOK: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid rgba(7,79,31)',
      background: '#eaffea',
    },
  }
  const handleDone = (el, id) => {
    const tempEl = el?.data
    tempEl.done = true
    updateOrCreateDocument('jobsNeed', id, tempEl).then((res) => {
      if (res) {
        getData()
      }
    })
  }
  const handleNotDone = (el, id) => {
    const tempEl = el?.data
    tempEl.done = true
    tempEl.done = false
    updateOrCreateDocument('jobsNeed', id, tempEl).then((res) => {
      if (res) {
        getData()
      }
    })
  }
  const getData = () => {
    getAllDataFromPath('jobsNeed').then((res) => {
      if (res) {
        setData(res)
      }
    })
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Typography sx={style.title}>
                  Lista próśb o ofert pracy
                </Typography>
                <Typography sx={style.description}>
                  Utwórz na podstawie dostępnych danych stanowisko w wybranej firmie.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          {
            data?.map((el, index) => {
              const dataEl = el.data
              return (
                <Box key={index} sx={dataEl?.done ? style.elementOK : style.element}>
                  <Typography>
                    <b>Do kiedy potrzeba: </b> {dataEl?.date}
                    <br/>
                    <b>Firma:</b> {dataEl?.company}, <b>Stanowisko: </b> {dataEl?.position} <br/>
                    {
                      dataEl?.upperForks && (
                        <>
                          <b>Widełki górne: </b> {dataEl?.upperForks} zł <br/>
                        </>
                      )
                    }
                    {
                      dataEl?.bottomForks && (
                        <>
                          <b>Widełki górne: </b> {dataEl?.bottomForks} zł <br/>
                        </>
                      )
                    }
                    <br/>
                    <b>Kto dodał: </b><br/> {dataEl?.createNameBy}, <b>Email:</b> {dataEl?.createEmailBy}, <b>Telefon: </b> {dataEl?.createPhoneBy}<br/><br/>
                    {
                      dataEl?.adds && (
                        <>
                          <b>Uwagi: </b> {dataEl?.adds}
                        </>
                      )
                    }
                  </Typography>
                  {
                    !dataEl?.done
                    ? (
                      <Button variant="contained" onClick={() => handleDone(el, el?.id)}>
                        Oznacz jako ukończone
                      </Button>
                    )
                    : (
                      <Button variant="contained" onClick={() => handleNotDone(el, el?.id)}>
                        Oznacz jako nie ukończone
                      </Button>
                    )
                  }
                  {
                    !dataEl?.done && (
                      <Button variant="contained" onClick={() => history.push(`/jobAdd/${el?.id}`)}>
                        Utwórz ofertę z prośby
                      </Button>
                    )
                  }
                </Box>
              )
            })
          }
        </Grid>
      </Grid>
    </div>
  )
}

export default JobsNeedListPage
