import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'
import { Box, Grid, Link, Typography } from '@mui/material'
import background from '../../assets/images/baner/bgBanner.png'
import { style } from '../../components/atoms/Slider/Slider.style'
import NamePage from '../../components/molecules/NamePage/NamePage'

const ContactPage = () => {
  return (
    <SizeWindow>
      <NamePage name="Kontakt">
        <Box sx={{ background: `url(${background}) no-repeat`, width: '100%', backgroundSize: '100%' }} className="contactForm">
          <Box sx={style.root}>
            <SizeWindow>
              <Grid container spacing={8}>
                <Grid item md={6} xs={12}>
                  <Typography sx={style.small}>
                    Masz pytania?
                  </Typography>
                  <Typography sx={style.title}>
                    Zostań w kontakcie z #Gramwgastro
                  </Typography>
                  <Typography sx={style.desc}>
                    Masz pytania, śmiało. Zadzwoń lub zostaw kontakt do siebie. Skontaktujemy się najszybciej jak to możliwe.
                  </Typography>
                  <Typography sx={style.desc}>
                    <b>Zadzwoń:</b> <Link href="tel:573261382" target="_blank">573 261 382</Link>
                  </Typography>
                  <Typography sx={style.desc}>
                    <b>Napisz:</b> <Link href="mailto:kontakt@gramwgastro.pl" target="_blank">kontakt@gramwgastro.pl</Link>
                  </Typography>
                  <Typography sx={style.desc}>
                    <b>Odwiedź:</b> ul. Giełdowa 14, Koszalin
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={style.box}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2333.8997553741647!2d16.205567000000002!3d54.199559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4701cd730f8c5fdf%3A0x8a8ed4f73cae0ba4!2sGie%C5%82dowa%2014%2C%2075-519%20Koszalin!5e0!3m2!1sen!2spl!4v1694415647139!5m2!1sen!2spl"
                      width="100%"
                      height="550"
                      style={{ border: 0, filter: 'grayscale(100%) invert(92%) contrast(83%)' }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    >
                    </iframe>
                  </Box>
                </Grid>
              </Grid>
            </SizeWindow>
          </Box>
        </Box>
      </NamePage>
    </SizeWindow>
  )
}

export default ContactPage
