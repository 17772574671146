import { Box, Button, Grid, Typography } from '@mui/material'
import plate1 from '../../../assets/images/baner/1.png'
import plate4 from '../../../assets/images/baner/2.png'
import plate5 from '../../../assets/images/baner/4.png'
import { style } from './Slider.style'
import SizeWindow from '../../molecules/SizeWindow/SizeWindow'
import background from '../../../assets/images/baner/bgBanner.png'
import { useHistory } from 'react-router'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const Slider = () => {
  const history = useHistory()
  return (
    <Box sx={{ background: `url(${background}) no-repeat`, width: '100%', backgroundSize: '100%' }}>
      <Box sx={style.root}>
        <SizeWindow>
          <Grid container spacing={8}>
            <Grid item md={6} xs={12}>
              <Typography sx={style.small}>
                Witaj, jesteś głodny?
              </Typography>
              <Typography sx={style.title}>
                My nie gotujemy, my tworzymy emocje!
              </Typography>
              <Typography sx={style.desc}>
                Gotowanie dla nas to nie tylko przygotowywanie jedzenia, ale przede wszystkim tworzenie niezapomnianych doświadczeń i emocji.
              </Typography>
              <Box sx={style.buttons}>
                <Button onClick={() => history.push('/jobs')} sx={style.button} variant="outlined" className="button">
                  Oferty pracy
                </Button>
                <Button onClick={() => history.push('/partners')} sx={style.button} variant="contained" className="button">
                  Zostań partnerem
                </Button>
              </Box>
            </Grid>
            {
              !BreakpointsHelper.isMobile() && (
                <Grid item md={6} xs={12}>
                  <Box sx={{ position: 'relative' }}>
                    <Typography sx={style.ummmy1}>
                      😋 Om-om-om...
                    </Typography>
                    <Typography sx={style.ummmy2}>
                      🥰 Jaaakie to jest dobre!
                    </Typography>
                    <Box sx={style.circle1} className="plate3" />
                    <Box sx={style.circle2} className="plate2" />
                    <Box sx={style.circle3} className="plate1" />
                    <Box sx={style.circle4} className="plate1" />
                    <img src={plate1} alt="plate1" style={style.plate1} className="plate1" />
                    <img src={plate4} alt="plate2" style={style.plate2} className="plate2" />
                    <img src={plate5} alt="plate3" style={style.plate3} className="plate3" />
                  </Box>
                </Grid>
              )
            }
          </Grid>
        </SizeWindow>
      </Box>
    </Box>
  )
}

export default Slider
