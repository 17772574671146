import PropTypes from 'prop-types'
import NavigationPreview from '../../components/molecules/NavigationPreview/NavigationPreview'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const PreviewLayout = (props) => {
  const Component = props.component
  const { location } = props
  const style = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'start',
      flexDirection: 'column',
      padding: BreakpointsHelper.isMobile() ? '180px 20px 0 20px' : '90px 0 0 0',
      width: 'calc(100% - 40px)'
    }
  }
  return (
    <div style={style.root}>
      <MagicLoader>
        <NavigationPreview location={location} {...props} />
        <Component location={location} {...props} />
      </MagicLoader>
    </div>
  )
}

PreviewLayout.defaultProps = {
  location: false
}

PreviewLayout.propsType = {
  location: PropTypes.object
}

export default PreviewLayout
