import { Box } from '@mui/material'

const SizeWindow = (props) => {
  const { children, background } = props
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      background,
    }
  }
  return (
    <Box sx={style.root}>
      <Box sx={{ width: '100%', margin: '0 auto', maxWidth: '1290px' }}>
        {children}
      </Box>
    </Box>
  )
}

export default SizeWindow
