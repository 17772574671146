import TranslationsEnum from '../../enums/LangEnum/LangEnum'
import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper'
import LangHelper from './LangHelper'

export default {
  setJSONLanguage: (lang) => {
    Object.keys(lang).map((el) => el === LocalStorageHelper.get('lang') && LocalStorageHelper.set('translation', lang[el]))
  },
  setDefaultLanguage: () => {
    if (!LocalStorageHelper.get('lang')) LocalStorageHelper.set('lang', TranslationsEnum.DEFAULT_LANG)
    LangHelper.setJSONLanguage(TranslationsEnum.LANG)
  },
  changeLang: (langChange, history) => {
    const lang = TranslationsEnum.LANG
    LocalStorageHelper.set('lang', langChange)
    LangHelper.setJSONLanguage(lang)
    history?.push(0)
  }
}
