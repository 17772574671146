import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import SizeWindow from '../SizeWindow/SizeWindow'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const NamePage = (props) => {
    const { children, name } = props
    const style = {
        root: {
            marginTop: BreakpointsHelper.isMobile() ? '0' : '50px',
            marginBottom: '120px',
        },
        title: {
            fontSize: '40px',
            color: '#231E41',
            fontWeight: '600',
            textAlign: 'center',
            lineHeight: '110%',
            letterSpacing: '-2px',
            marginTop: '30px',
            marginBottom: '30px',
        },
    }
    return (
        <SizeWindow>
            <div style={style.root}>
                <Breadcrumbs style={{ color: '#231E41' }} aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/home">
                        Strona główna
                    </Link>
                    <Typography color="text.primary">{name}</Typography>
                </Breadcrumbs>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Typography sx={style.title}>
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Box sx={{ marginTop: '20px' }}>
                            {children}
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </SizeWindow>
    )
}

export default NamePage
