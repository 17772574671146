import pl from '../../translations/pl'

export default {
  DEFAULT_LANG: 'pl',
  AVAILABLE_LANGUAGES: ['pl'],
  LANG: {
    pl,
  },
  LOCALE_COOKIE_NAME: 'locale',
}
