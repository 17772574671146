import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import StringHelper from '../../../../helpers/StringHelper/StringHelper'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

const InputPassword = ({ name, label, required, disabled, value, onChange, error, onBlur, onKeyUp, helperText }) => {
  const [showPassword, setShowPassword] = useState(false)
  const style = {
    root: {
      width: '100%',
      marginBottom: '15px',
    },
    error: {
      color: '#d32f2f',
      fontSize: '13px',
      marginLeft: '15px',
      marginTop: '10px',
    },
  }
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const generateLabel = () => `${StringHelper.toCapitalize(label)} ${required && '*'}`
  return (
		<FormControl sx={style.root} error={Boolean(error)} variant="outlined">
			<InputLabel htmlFor={name}>{generateLabel()}</InputLabel>
			<OutlinedInput
				id={name}
				type={showPassword ? 'text' : 'password'}
				value={value}
				name={name}
				onChange={onChange}
				required={required}
				disabled={disabled}
				autoComplete="off"
				onBlur={onBlur}
				onKeyUp={onKeyUp}
				endAdornment={
					<InputAdornment position="end">
						<IconButton onClick={() => handleClickShowPassword()} edge="end">
							{showPassword ? <VisibilityOff/> : <Visibility/>}
						</IconButton>
					</InputAdornment>
				}
				label={generateLabel()}
			/>
			{
				helperText && (
					<Typography
						sx={{
						  paddingLeft: '15px',
						  fontSize: '13px',
						  marginTop: '5px',
						}}
					>
						{helperText}
					</Typography>
				)
			}
			{
				error && (
					<div style={style.error}>
						{StringHelper.toCapitalize(error)}
					</div>
				)
			}
		</FormControl>
  )
}

InputPassword.defaultProps = {
  required: true,
  disabled: false,
  error: false,
  onChange: null,
  onBlur: null,
  onKeyUp: null,
}

InputPassword.propsType = {
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
}

export default InputPassword
