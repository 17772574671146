import { BrowserRouter as Router, Switch } from 'react-router-dom'
import MainLayout from './layouts/MainLayout/MainLayout'
import { Redirect } from 'react-router'
import './assets/scss/App.scss'
import { PublicRoute } from './router/PublicRoute/PublicRoute'
import LoginPage from './pages/LoginPage/LoginPage'
import SimpleLayout from './layouts/SimpleLayout/SimpleLayout'
import { PrivateRoute } from './router/PrivateRoute/PrivateRoute'
import HomePage from './pages/HomePage/HomePage'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import AdminPage from './pages/AdminPage/AdminPage'
import RememberPage from './pages/RememberPage/RememberPage'
import LangHelper from './helpers/LangHelper/LangHelper'
import JobsPage from './pages/JobsPage/JobsPage'
import ContactPage from './pages/ContactPage/ContactPage'
import OrderPage from './pages/OrderPage/OrderPage'
import PricesPage from './pages/PricesPage/PricesPage'
import PartnersPage from './pages/PartnersPage/PartnersPage'
import JobsListPage from './pages/JobsListPage/JobsListPage'
import CommodityListPage from './pages/CommodityListPage/CommodityListPage'
import RecruitmentListPage from './pages/RecruitmentListPage/RecruitmentListPage'
import SupplierListPage from './pages/SupplierListPage/SupplierListPage'
import TrainingPage from './pages/TrainingPage/TrainingPage'
import JobAddPage from './pages/JobAddPage/JobAddPage'
import JobApplyPage from './pages/JobApplyPage/JobApplyPage'
import JobNeedPage from './pages/JobNeedPage/JobNeedPage'
import JobsNeedListPage from './pages/JobsNeedListPage/JobsNeedListPage'
import BlogPage from './pages/BlogPage/BlogPage'
import PostAddPage from './pages/PostAddPage/PostAddPage'
import PostsListPage from './pages/PostsListPage/PostsListPage'
import PostPage from './pages/PostPage/PostPage'
import ServicesPage from './pages/ServicesPage/ServicesPage'
import EventsPage from './pages/EventsPage/EventsPage'
import BookkeepingPage from './pages/BookkeepingPage/BookkeepingPage'
import FundationsPage from './pages/FundationPage/FundationsPage'

const App = () => {
  LangHelper.setDefaultLanguage()
  return (
    <Router basename={'/'}>
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/home'}/>} layout={SimpleLayout}/>
        <PublicRoute path={'/home'} component={HomePage} layout={PreviewLayout}/>
        <PublicRoute path={'/login'} component={LoginPage} layout={SimpleLayout}/>
        <PublicRoute path={'/jobs'} component={JobsPage} layout={PreviewLayout}/>
        <PublicRoute path={'/jobApply/:id'} component={JobApplyPage} layout={PreviewLayout}/>
        <PublicRoute path={'/orders'} component={OrderPage} layout={PreviewLayout}/>
        <PublicRoute path={'/prices'} component={PricesPage} layout={PreviewLayout}/>
        <PublicRoute path={'/services'} component={ServicesPage} layout={PreviewLayout}/>
        <PublicRoute path={'/events'} component={EventsPage} layout={PreviewLayout}/>
        <PublicRoute path={'/bookkeeping'} component={BookkeepingPage} layout={PreviewLayout}/>
        <PublicRoute path={'/partners'} component={PartnersPage} layout={PreviewLayout}/>
        <PublicRoute path={'/training'} component={TrainingPage} layout={PreviewLayout}/>
        <PublicRoute path={'/blog'} component={BlogPage} layout={PreviewLayout}/>
        <PublicRoute path={'/fundation'} component={FundationsPage} layout={PreviewLayout}/>
        <PublicRoute path={'/post/:id'} component={PostPage} layout={PreviewLayout}/>
        <PublicRoute path={'/contact'} component={ContactPage} layout={PreviewLayout}/>
        <PublicRoute path={'/register'} component={RegisterPage} layout={SimpleLayout}/>
        <PublicRoute path={'/reset'} component={RememberPage} layout={SimpleLayout}/>
        <PrivateRoute path={'/admin'} component={AdminPage} layout={MainLayout}/>
        <PrivateRoute path={'/jobsList'} component={JobsListPage} layout={MainLayout}/>
        <PrivateRoute path={'/jobAdd'} component={JobAddPage} layout={MainLayout}/>
        <PrivateRoute path={'/jobNeed'} component={JobNeedPage} layout={MainLayout}/>
        <PrivateRoute path={'/jobsNeedList'} component={JobsNeedListPage} layout={MainLayout}/>
        <PrivateRoute path={'/postAdd'} component={PostAddPage} layout={MainLayout}/>
        <PrivateRoute path={'/postsList'} component={PostsListPage} layout={MainLayout}/>
        <PrivateRoute path={'/commodityList'} component={CommodityListPage} layout={MainLayout}/>
        <PrivateRoute path={'/recruitmentList'} component={RecruitmentListPage} layout={MainLayout}/>
        <PrivateRoute path={'/supplierList'} component={SupplierListPage} layout={MainLayout}/>
        <PrivateRoute path={'/profile'} component={ProfilePage} layout={MainLayout}/>
      </Switch>
    </Router>
  )
}

export default App
