import CommingSoon from '../../components/molecules/CommingSoon/CommingSoon'
import NamePage from '../../components/molecules/NamePage/NamePage'

const OrderPage = () => {
  return (
      <NamePage name="Zamów jedzenie">
          <CommingSoon />
      </NamePage>
  )
}

export default OrderPage
