import NamePage from '../../components/molecules/NamePage/NamePage'
import { Box, Grid, Typography } from '@mui/material'
import imageAbout1 from '../../assets/images/sfsdffssf.jpg'
import imageAbout2 from '../../assets/images/steak-beef-beef-steak-medium-with-red-pepper-aromatic-herbs-fried-onion.jpg'
import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'

const ServicesPage = () => {
    const style = {
        root: {
            marginTop: '200px',
            marginBottom: '200px',
        },
        img: {
            width: '100%',
            minHeight: '500px',
            objectFit: 'cover',
        },
        title: {
            color: '#231E41',
            fontSize: '40px',
            fontWeight: '600',
            lineHeight: '110%',
            letterSpacing: '-2px',
            marginBottom: '30px',
        },
        textJustify: {
            fontSize: '18px',
            color: '#6F6F87',
            marginBottom: '10px',
            textAlign: 'justify',
        },
        text: {
            fontSize: '18px',
            color: '#6F6F87',
            marginBottom: '10px'
        },
        circle1: {
            width: '25px',
            height: '25px',
            background: '#074F1F',
            borderRadius: '50%',
            position: 'absolute',
            top: '0',
            left: '-20px',
            zIndex: 3,
            filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))',
        },
        circle2: {
            width: '50px',
            height: '50px',
            background: '#074F1F',
            borderRadius: '50%',
            position: 'absolute',
            bottom: '-20px',
            left: '50px',
            zIndex: 3,
            filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))',
        },
        circle3: {
            width: '80px',
            height: '80px',
            border: '30px solid white',
            borderRadius: '50%',
            position: 'absolute',
            bottom: '-50px',
            right: '-50px',
            zIndex: 3,
            filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
        },
        circle4: {
            width: '50px',
            height: '50px',
            border: '20px solid white',
            borderRadius: '50%',
            position: 'absolute',
            bottom: '50px',
            left: '20px',
            zIndex: 3,
            filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
        },
    }
  return (
      <NamePage name="Nasze usługi">
          <SizeWindow>
              <Box sx={style.root}>
                  <Grid container spacing={12}>
                      <Grid item md={6} xs={12}>
                          <img src={imageAbout1} alt="about us" style={style.img} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                          <Typography sx={style.title}>
                              Mistrzostwo w Zarządzaniu Gastronomią
                          </Typography>
                          <Typography sx={style.textJustify}>
                              Zarządzanie gastronomią to sztuka, której można się nauczyć pod okiem ekspertów z Grupy <b>Gram w Gastro</b>. Proponujemy intensywne szkolenia i warsztaty, które pomogą uczestnikom opanować kluczowe umiejętności zarządzania restauracją, kawiarnią czy barem. Od strategii działania, przez wybór odpowiedniego personelu, aż po efektywne zarządzanie finansami - nasze szkolenia pokrywają wszystkie aspekty prowadzenia biznesu gastronomicznego.                          </Typography>
                      </Grid>
                  </Grid>
              </Box>
              <Box sx={style.root}>
                  <Grid container spacing={12}>
                      <Grid item md={6} xs={12}>
                          <Typography sx={style.title}>
                              Gastronomia, Marketing i Księgowość w Jednym Pakiecie
                          </Typography>
                          <Typography sx={style.textJustify}>
                              Sektor gastronomiczny to nie tylko gotowanie i serwowanie potraw, ale również skuteczny marketing i dokładna księgowość. Nasze warsztaty poświęcone są najnowszym trendom w promocji restauracji oraz efektywnym metodom zarządzania finansami. Ponadto, proponujemy wyjątkowe eventy, podczas których uczestnicy mogą wymieniać się doświadczeniami, nawiązywać kontakty oraz czerpać inspiracje od czołowych specjalistów branży.                          </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                          <img src={imageAbout2} alt="about us" style={style.img} />
                      </Grid>
                  </Grid>
              </Box>
          </SizeWindow>
      </NamePage>
  )
}

export default ServicesPage
