import { Box, Grid, Typography } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useEffect, useState } from 'react'
import { updateOrCreateDocument } from '../../firebase'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { useSnackbar } from 'notistack'
import PositionHelper from '../../helpers/PositionHelper/PositionHelper'

const JobNeedPage = () => {
  const [companies, setCompanies] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'position',
        type: 'select',
        items: PositionHelper.getAll(),
        label: 'Stanowisko',
        validationType: 'string',
      },
      {
        name: 'company',
        type: 'select',
        items: companies,
        label: 'Wybierz firmę do jakiej potrzebujesz',
      },
      {
        name: 'date',
        type: 'date',
        value: '2023/05/25',
        label: 'Do kiedy go potrzebujesz',
      },
      {
        name: 'upperForks',
        type: 'number',
        label: 'Widełki górne',
        validationType: 'string',
        required: false,
      },
      {
        name: 'bottomForks',
        type: 'number',
        label: 'Widełki dolne',
        validationType: 'string',
        required: false,
      },
      {
        name: 'adds',
        type: 'text',
        label: 'Dodatkowe uwagi',
        validationType: 'string',
        required: false,
      },
      {
        type: 'button',
        value: 'Wyślij prośbę o ofertę pracy',
      },
    ]
  }
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  }
  const handleData = (e) => {
    e.createEmailBy = LocalStorageHelper.get('user')?.email
    e.createNameBy = LocalStorageHelper.get('user')?.name
    e.createPhoneBy = LocalStorageHelper.get('user')?.phone
    e.create = new Date()
    updateOrCreateDocument('jobsNeed', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Udało się wysłać zapytanie poprawnie, skonsultujemy rekrutacje, przed wypuszczeniem live.', { variant: 'success' })
      }
    })
  }
  useEffect(() => {
    const companiesLocal = LocalStorageHelper.get('user')?.companies
    if (companiesLocal?.length > 0) {
      const temp = []
      companiesLocal?.forEach((el) => {
        temp.push({ value: el, name: el })
      })
      setCompanies(temp)
    }
  }, [])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Typography sx={style.title}>
                  Prośba o ofertę pracy
                </Typography>
                <Typography sx={style.description}>
                  Dodaj na jakie stanowisko pracy potrzebujesz pracownika?
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGenerator data={formData} submit={(e) => handleData(e)} />
        </Grid>
      </Grid>
    </div>
  )
}

export default JobNeedPage
