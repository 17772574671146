import { Box, Button, Grid, Typography } from '@mui/material'
import uno from '../../assets/images/partners/1.png'
import green from '../../assets/images/partners/2.jpg'
import NamePage from '../../components/molecules/NamePage/NamePage'
import { useEffect, useState } from 'react'
import { getAllElementsByName } from '../../firebase'
import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'
import SkeletonLoader from '../../components/atoms/SkeletionLoader/SkeletonLoader'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const JobsPage = (props) => {
    const [data, setData] = useState(null)
    const [companies, setCompanies] = useState(null)
    const style = {
        job: {
            display: 'flex',
            flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row',
            alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
            justifyContent: 'flex-start',
            padding: '20px',
            boxShadow: '2px 2px 10px 5px rgba(0,0,0,.1)',
            cursor: 'pointer',
        },
        image: {
            height: '100%',
            width: '80px',
            marginRight: '20px',
        },
        jobName: {
            fontSize: '25px',
            color: '#231E41',
            fontWeight: '400',
        },
        text: {
            display: 'flex',
            flexDirection: 'column',
        },
    }
    useEffect(() => {
        getAllElementsByName('jobs').then((res) => res && setData(res))
        getAllElementsByName('partners').then((res) => {
            if (res) {
                const temp = []
                res?.forEach(el => {
                    if (el.inGramWGastro) {
                        temp.push(el)
                    }
                })
                setCompanies(temp)
            }
        })
    }, [])
    return (
        <SizeWindow>
            <NamePage name="Oferty pracy">
                <Grid container spacing={2}>
                    <SkeletonLoader variable={data?.length} time={1000} count={3}>
                    {
                        data?.length > 0
                          ? data?.map((el, index) => {
                              const company = companies?.find(element => element.company === el.company)
                                if (!el?.disabled) {
                                    return (
                                      <Grid item md={12} key={index}>
                                          <Box sx={style.job} className="sizeAnimation">
                                              <Box sx={{ width: '100px' }}>
                                                  <img src={company?.logo} alt={el.name} style={style.image} />
                                              </Box>
                                              <Box sx={style.text}>
                                                  <Typography sx={style.jobName}>
                                                      {el.position}
                                                  </Typography>
                                                  <Typography sx={style.description}>
                                                      <b>Firma:</b> {el.company}, <b>Rodzaj umowy:</b> {el.docs}, <b>Miasto:</b> {el.city}
                                                  </Typography>
                                              </Box>
                                              <Box sx={{ flex: 1, marginLeft: BreakpointsHelper.isMobile() ? '0' : '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: BreakpointsHelper.isMobile() ? '10px' : '0' }}>
                                                  <Button
                                                    variant="contained"
                                                    onClick={() => props.location.history.push(`/jobApply/${el.id}`)}
                                                  >
                                                      Podgląd oferty
                                                  </Button>
                                              </Box>
                                          </Box>
                                      </Grid>
                                    )
                                } else {
                                    return ''
                                }
                          })
                          : (
                            <Typography>
                                Brak dostępnych ofert pracy
                            </Typography>
                          )
                    }
                    </SkeletonLoader>
                </Grid>
            </NamePage>
        </SizeWindow>
    )
}

export default JobsPage
