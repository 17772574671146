import { useEffect, useState } from 'react'
import { getAllDataFromPath } from '../../firebase'
import { Box, Button, Grid, Typography } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { useHistory } from 'react-router'

const PostsListPage = () => {
  const history = useHistory()
  const [posts, setPosts] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    box: {
      padding: '10px 20px',
      boxShadow: '2px 2px 20px 1px rgba(0,0,0,0.15)',
      marginBottom: '30px',
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    description: {
      fontSize: '16px',
      lineHeight: '110%',
      fontWeight: '300',
      marginTop: '5px',
    },
    element: {
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid rgba(7,79,31)',
      background: '#ffeaea',
    },
    elementOK: {
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid rgba(7,79,31)',
      background: '#eaffea',
    },
  }
  useEffect(() => {
    getAllDataFromPath('posts').then((res) => {
      if (res) {
        setPosts(res)
      }
    })
  }, [])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={8} xs={12}>
                <Typography sx={style.title}>
                  Lista postów
                </Typography>
                <Typography sx={style.description}>
                  Możliwość zobaczenia listy postów, ich edycja oraz dodanie nowego posta.
                </Typography>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end', height: '100%' }}>
                  <Button
                    sx={{ marginTop: '10px', marginRight: '10px', marginBottom: '10px' }}
                    variant="contained"
                    onClick={() => history.push('/postAdd')}
                  >
                    Dodaj posta
                  </Button>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                {
                  posts?.length
                    ? posts?.map((data, index) => {
                      const el = data?.data
                      return (
                        <Box key={index} sx={style.elementOK}>
                          <Typography sx={{ marginBottom: '5px' }}><b>ID Posta:</b> {data.id}</Typography>
                          <Typography sx={{ marginBottom: '5px' }}><b>Tytuł:</b> {el.title}</Typography>
                          <Typography sx={{ marginBottom: '5x' }}>
                            <b>Opis: </b><span dangerouslySetInnerHTML={{ __html: el.description }} />
                          </Typography>
                          <Button
                            sx={{ marginTop: '10px', marginRight: '10px', marginBottom: '10px' }}
                            variant="contained"
                            onClick={() => history.push(`/postAdd/${data.id}`)}
                          >
                            Edytuj posta
                          </Button>
                        </Box>
                      )
                    })
                    : (
                      <Typography>
                        Brak postów
                      </Typography>
                    )
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default PostsListPage
