import NamePage from '../../components/molecules/NamePage/NamePage'
import { Box, Grid, Typography } from '@mui/material'
import imageAbout0 from '../../assets/images/kucharzblog-min.jpg'
import imageAbout2 from '../../assets/images/abce3a4c-11f3-4b79-a083-d72a3ab9eefe_f1400x900.jpeg'
import imageAbout1 from '../../assets/images/Akademia-8.jpg'
import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'

const TrainingPage = () => {
  const style = {
    root: {
      marginTop: '200px',
      marginBottom: '200px'
    },
    img: {
      width: '100%',
      minHeight: '500px',
      objectFit: 'cover'
    },
    title: {
      color: '#231E41',
      fontSize: '40px',
      fontWeight: '600',
      lineHeight: '110%',
      letterSpacing: '-2px',
      marginBottom: '30px'
    },
    textJustify: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px',
      textAlign: 'justify'
    },
    text: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px'
    },
    circle1: {
      width: '25px',
      height: '25px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      top: '0',
      left: '-20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))'
    },
    circle2: {
      width: '50px',
      height: '50px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-20px',
      left: '50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))'
    },
    circle3: {
      width: '80px',
      height: '80px',
      border: '30px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-50px',
      right: '-50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))'
    },
    circle4: {
      width: '50px',
      height: '50px',
      border: '20px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '50px',
      left: '20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))'
    }
  }
  return (
    <NamePage name="Warsztaty i Szkolenia">
      <SizeWindow>
        <Box sx={style.root}>
          <Grid container spacing={12}>
            <Grid item md={6} xs={12}>
              <img src={imageAbout0} alt="about us" style={style.img}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={style.title}>
                Warsztaty Zarządzania Gastronomią
              </Typography>
              <Typography sx={style.textJustify}>
                Od Rachunkowości do Marketingu!

                Grupa gastronomiczna Gram, w partnerstwie z Taste Tomorrow, prezentuje ekskluzywne warsztaty dedykowane
                dla właścicieli restauracji, menedżerów oraz pasjonatów branży gastronomicznej. Uczestnicy będą mieli
                okazję zgłębić tajniki zarządzania firmą gastronomiczną - począwszy od podstaw rachunkowości, a na
                strategiach marketingowych kończąc. Szkolenie oferuje praktyczne wskazówki i narzędzia, które pomogą w
                efektywnym prowadzeniu działalności w dynamicznie zmieniającej się branży.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={style.root}>
          <Grid container spacing={12}>
            <Grid item md={6} xs={12}>
              <Typography sx={style.title}>
                Kulinarne Odkrycia
              </Typography>
              <Typography sx={style.textJustify}>
                Dla tych, którzy pragną wnieść swoje umiejętności kulinarne na wyższy poziom, Gram w gastro i Taste
                Tomorrow
                przedstawiają serię profesjonalnych warsztatów kulinarnych. Pod okiem doświadczonych szefów kuchni
                uczestnicy poznają najnowsze trendy w gastronomii, techniki kulinarne oraz sekrety tworzenia
                pamiętających się dań. To niepowtarzalna
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <img src={imageAbout2} alt="about us" style={style.img}/>
            </Grid>
          </Grid>
        </Box>
        <Box sx={style.root}>
          <Grid container spacing={12}>
            <Grid item md={6} xs={12}>
              <img src={imageAbout1} alt="about us" style={style.img}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={style.title}>
                Gastro-Biznes od Podstaw
              </Typography>
              <Typography sx={style.textJustify}>
                Jak Otworzyć i Prowadzić Udany Lokal? Marzysz o własnej restauracji czy kawiarni? Grupa Gram we
                współpracy z Taste Tomorrow przedstawia kurs dedykowany przyszłym przedsiębiorcom w branży
                gastronomicznej. Od wyboru lokalizacji, przez skomplikowane aspekty prawne, po zarządzanie personelem -
                uczestnicy dowiedzą się, jak krok po kroku otworzyć i prowadzić udaną gastronomiczną inwestycję.
                Zapraszamy na intensywne szkolenie, które zaowocuje wiedzą i praktyką!
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </SizeWindow>
    </NamePage>
  )
}

export default TrainingPage
