import background from '../../assets/images/baner/bgBanner.png'
import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'
import { Box, Grid, Typography } from '@mui/material'
import NamePage from '../../components/molecules/NamePage/NamePage'
import imageAbout1 from '../../assets/images/young-woman-with-eyes-closed-enjoying-taste-food-while-eating-with-friends-dining-table.jpg'
import imageAbout2 from '../../assets/images/happy-man-food-dining-home-profession-chef-uniform-professional-cook-male-kitchen-cuisine-restaurant.jpg'
import imageAbout3 from '../../assets/images/restaurant-woman-food-standing-portrait-holding-smile-cook-waiter-indoors-job-generative-ai.jpg'

const FundationsPage = () => {
  const style = {
    root: {
      marginTop: '200px',
      marginBottom: '200px'
    },
    img: {
      width: '100%',
      minHeight: '500px',
      objectFit: 'cover'
    },
    title: {
      color: '#231E41',
      fontSize: '40px',
      fontWeight: '600',
      lineHeight: '110%',
      letterSpacing: '-2px',
      marginBottom: '30px'
    },
    textJustify: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px',
      textAlign: 'justify'
    },
    text: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px'
    },
    circle1: {
      width: '25px',
      height: '25px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      top: '0',
      left: '-20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))'
    },
    circle2: {
      width: '50px',
      height: '50px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-20px',
      left: '50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))'
    },
    circle3: {
      width: '80px',
      height: '80px',
      border: '30px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-50px',
      right: '-50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))'
    },
    circle4: {
      width: '50px',
      height: '50px',
      border: '20px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '50px',
      left: '20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))'
    }
  }
  return (
    <SizeWindow>
      <NamePage name="Fundacja">
        <Box sx={{ background: `url(${background}) no-repeat`, width: '100%', backgroundSize: '100%' }}>
          <Box sx={style.root}>
            <SizeWindow>
              <Grid container spacing={8}>
                <Grid item md={6} xs={12}>
                  <img src={imageAbout1} alt="fundations" style={style.img}/>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography sx={{ marginBottom: '20px' }}>
                    Fundacja Gram w gastro jest podmiotem ekonomii społecznej, które angażuje się w różnorodne usługi
                    gastronomiczne, promując jednocześnie wartości społeczne i kulturalne. Nasza działalność obejmuje
                    szeroki zakres usług, w tym dostarczanie posiłków dla lokali gastronomicznych poprzez naszą
                    zamawiarkę jedzenia ZJEDZ KOSZALIN, organizację eventów gastronomicznych oraz wykonywanie cateringów
                    okolicznościowych.
                  </Typography>
                  <Typography sx={{ marginBottom: '20px' }}>
                    Jako fundacja, nasze działania nie ograniczają się jedynie do zysku finansowego. Naszym celem jest
                    także wsparcie lokalnej społeczności poprzez tworzenie miejsc pracy, promowanie zdrowego stylu życia
                    i kultywowanie tradycji kulinarnej i dawanie rozrywki w trudnych czasach. Dzięki naszym usługom
                    gastronomicznym chcemy nie tylko zaspokoić podniebienia naszych klientów, ale także inspirować ich
                    do aktywnego uczestnictwa w życiu społecznym poprzez doświadczenie kulinarnych przygód.
                  </Typography>
                  <Typography sx={{ marginBottom: '20px' }}>
                    Nasza fundacja nie tylko stawia sobie za cel dostarczanie wysokiej jakości usług gastronomicznych,
                    ale także angażuje się w działania społeczne i wspiera lokalną społeczność poprzez zatrudnianie osób
                    z grupy defaworyzowanych, promowanie zdrowego stylu życia oraz dbanie o środowisko naturalne poprzez
                    odpowiedzialne zarządzanie odpadami i wybór lokalnych dostawców. Jesteśmy dumni z naszych osiągnięć
                    i ciągle dążymy do doskonalenia naszych usług, aby sprostać oczekiwaniom naszych klientów oraz
                    wyznaczonym celom społecznym.
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <img src={imageAbout2} alt="fundations" style={style.img}/>
                </Grid>
                <Grid item md={6} xs={12}>
                  <img src={imageAbout3} alt="fundations" style={style.img}/>
                </Grid>
              </Grid>
            </SizeWindow>
          </Box>
        </Box>
      </NamePage>
    </SizeWindow>
  )
}

export default FundationsPage
