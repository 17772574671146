import { Box, Grid, Typography } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { useEffect, useState } from 'react'
import { getAllDataFromPath } from '../../firebase'

const RecruitmentListPage = () => {
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    box: {
      padding: '10px 20px',
      boxShadow: '2px 2px 20px 1px rgba(0,0,0,0.15)',
      marginBottom: '30px',
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    description: {
      fontSize: '16px',
      lineHeight: '110%',
      fontWeight: '300',
      marginTop: '5px',
    },
    titleBox: {
      margin: '15px 0 5px 0',
    },
    elementOK: {
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid rgba(7,79,31)',
      background: '#eaffea',
    },
  }
  useEffect(() => {
    getAllDataFromPath('jobs').then((res) => {
      if (res) {
        setData(res)
      }
    })
  }, [])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={style.title}>
                  Rekrutacja
                </Typography>
                <Typography sx={style.description}>
                  Możliwość sprawdzenia wybranego kandydata i oznaczenie jego doświadczenia i umiejętności na podstawie wybranego stanowiska na które rekrutuje.
                </Typography>
              </Grid>
              <Grid item md={12}>
                {
                  data?.length
                    ? data?.map((el, index) => {
                      const dataEl = el?.data
                      return (
                        <div key={index}>
                          {
                            dataEl?.users?.length > 0 && dataEl?.users?.map((user, indexUser) => {
                              return (
                                <Box key={indexUser} sx={style.elementOK}>
                                  <Box sx={{ marginRight: '50px', flexDirection: 'column', display: 'flex' }}>
                                    <b style={style.titleBox}>Stanowisko: </b> {dataEl.position}
                                    <b style={style.titleBox}>Firma: </b> {dataEl.company}
                                    <b style={style.titleBox}>Miasto: </b> {dataEl.city}
                                  </Box>
                                  <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                                    <b style={style.titleBox}>Imię i nazwisko:</b> {user.firstName} {user.lastName}
                                    <b style={style.titleBox}>Email:</b> {user.email}
                                    <b style={style.titleBox}>Telefon:</b> {user.phone}
                                    <b style={style.titleBox}>CV:</b> <a href={user.cv}>Link</a>
                                  </Box>
                                </Box>
                              )
                            })
                          }
                        </div>
                      )
                    })
                    : (
                      <Typography>
                        Brak dodanych ofert pracy
                      </Typography>
                    )
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default RecruitmentListPage
