import * as React from 'react'
import Box from '@mui/material/Box'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useState } from 'react'

const CheckboxesGroup = ({ label, values, setFieldValue, keyIndex }) => {
  const [state, setState] = useState({
    gilad: true,
    jason: false,
    antoine: false,
  })

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
	  setFieldValue([event.target.name], event.target.checked)
  }
  return (
		<Box sx={{ display: 'flex' }} key={keyIndex}>
			<FormControl sx={{ marginBottom: '15px' }} component="fieldset" variant="standard">
				<FormLabel component="legend">{label}</FormLabel>
				<FormGroup>
					{
						values.map((el, index) => {
						  return (
								<FormControlLabel
									key={index}
									control={
										<Checkbox
											checked={state[el.value]}
											onChange={handleChange}
											name={el.value}
										/>
									}
									label={el.label}
								/>
						  )
						})
					}
				</FormGroup>
			</FormControl>
		</Box>
  )
}

export default CheckboxesGroup
