import React from 'react'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import UserHelper from '../../helpers/UserHelper/UserHelper'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import StringHelper from '../../helpers/StringHelper/StringHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'

const RegisterPage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const { enqueueSnackbar } = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'firstName',
        type: 'text',
        label: t.globals.inputs.firstName.label,
        validationType: 'string',
      },
      {
        name: 'secondName',
        type: 'text',
        label: t.globals.inputs.secondName.label,
        validationType: 'string',
      },
      {
        name: 'email',
        type: 'email',
        label: t.globals.inputs.email.label,
        validationType: 'string',
      },
      {
        name: 'password',
        type: 'password',
        label: t.globals.inputs.password.label,
        helperText: t.globals.inputs.password.helperText,
        validationType: 'string',
      },
      {
        name: 'phone',
        type: 'phone',
        label: t.globals.inputs.phone.label,
        validationType: 'string',
      },
      {
        name: 'regulations',
        type: 'checkbox',
        label: 'Akceptacja regulaminu oraz polityki prywatności dostępnego pod adresem gramwgastro.pl',
        validationType: 'string',
      },
      {
        type: 'button',
        value: 'Zarejestruj',
      }
    ],
  }
  const style = {
    root: {
      background: 'white',
      padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '25px',
      fontWeight: 700,
      maxWidth: BreakpointsHelper.isMobile() ? 'none' : '320px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    desc: {
      maxWidth: '320px',
      fontSize: BreakpointsHelper.isMobile() ? '16px' : '18px',
      fontWeight: BreakpointsHelper.isMobile() ? '400' : '300',
      textTransform: 'none',
      textAlign: 'center',
    },
    button: {
      background: '#1b0f23',
      color: 'white',
      fontSize: '16px',
      fontWeight: 700,
      padding: '5px 25px',
      width: '100%',
      marginTop: '20px',
    },
    facebookButton: {
      display: 'none',
    },
    input: {
      marginBottom: '5px',
      marginTop: '5px',
    },
    inputFirst: {
      marginBottom: '5px',
      marginTop: '25px',
    },
  }
  const handleRegister = (e) => {
    e.firstName = StringHelper.toCapitalize(e.firstName)
    e.lastName = StringHelper.toCapitalize(e.lastName)
    UserHelper.register(e).then((res) => {
      if (res) enqueueSnackbar(t.registerPage.toasts.success, { variant: 'success' })
      else enqueueSnackbar(t.registerPage.toasts.error, { variant: 'error' })
    }).catch((error) => error)
  }
  return (
		<div style={style.root}>
      Rejestracja
			<div style={style.desc}>
        Utwórz konto podając dane ponieżej i wybierając przycisk zarejestruj.
			</div>
			<FormGenerator data={formData} submit={(e) => handleRegister(e)} {...props}/>
			<Button
				fullWidth
				variant="outlined"
        sx={{ maxWidth: '320px' }}
				onClick={() => props.location.history.push('/login')}
			>
        Zaloguj
			</Button>
		</div>
  )
}

export default RegisterPage
