import { Box, Grid, Typography } from '@mui/material'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { useEffect, useState } from 'react'
import { getAllDataFromPath, updateOrCreateDocument } from '../../firebase'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router'
import SkeletonLoader from '../../components/atoms/SkeletionLoader/SkeletonLoader'

const PostAddPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const idPost = history?.location?.pathname?.split('/')?.length === 3 ? history?.location?.pathname?.split('/')[2] : null
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  }
  const [formData, setFormData] = useState(null)
  const [postId, setPostId] = useState(null)
  const handleData = (e) => {
    if (idPost) {
      updateOrCreateDocument('posts', idPost, e).then((res) => {
        if (res) {
          enqueueSnackbar('Edytowano poprawnie posta.', { variant: 'success' })
          setTimeout(() => {
            history.push('/postsList')
          }, 1000)
        }
      })
    } else {
      updateOrCreateDocument('posts', null, e).then((res) => {
        if (res) {
          enqueueSnackbar('Dodano poprawnie posta.', { variant: 'success' })
          setTimeout(() => {
            history.push('/postsList')
          }, 1000)
        }
      })
    }
  }
  useEffect(() => {
    const tempForm = {
      elements: [
        {
          type: 'text',
          name: 'title',
          label: 'Tytuł',
          value: postId?.title || '',
          validationType: 'string',
        },
        {
          type: 'editor',
          name: 'description',
          label: 'Opis',
          value: postId?.description || '',
          validationType: 'string',
        },
        {
          type: 'text',
          name: 'image',
          value: postId?.image || '',
          label: 'URL do zdjęcia',
          validationType: 'string',
        },
        {
          type: 'button',
          value: 'Zapisz',
        }
      ]
    }
    setFormData(tempForm)
  }, [postId])
  useEffect(() => {
    if (idPost) {
      getAllDataFromPath('posts').then((res) => {
        if (res) {
          res?.forEach(el => {
            if (el.id === idPost) {
              setPostId(el.data)
            }
          })
        }
      })
    }
  }, [idPost])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Typography sx={style.title}>
                  {
                    idPost ? `Edycja posta o id: ${idPost}` : 'Dodawanie posta do bloga'
                  }
                </Typography>
                <Typography sx={style.description}>
                  {idPost ? 'Edytuj' : 'Dodaj'} wszystkie pola posta {idPost ? '' : 'i dodaj nowego do bloga'}.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          {
            idPost
            ? (
              <SkeletonLoader variable={postId}>
                <FormGenerator data={formData} submit={(e) => handleData(e)} />
              </SkeletonLoader>
            )
            : (
              <FormGenerator data={formData} submit={(e) => handleData(e)} />
            )
          }
        </Grid>
      </Grid>
    </div>
  )
}

export default PostAddPage
