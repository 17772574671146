import { Box, Typography } from '@mui/material'
import commingSoon from '../../../assets/images/commingSoon.png'

const CommingSoon = () => {
  const style = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      width: 'auto',
      maxWidth: '100%',
      maxHeight: '300px',
      marginBottom: '50px',
      marginTop: '50px',
    },
    title: {
      fontSize: '28px',
      lineHeight: '110%',
      fontWeight: '300',
      maxWidth: '500px',
      margin: '0 auto',
      textAlign: 'center',
    }
  }
  return (
    <Box sx={style.root}>
      <img style={style.img} src={commingSoon} alt="comming soon" />
      <Typography sx={style.title}>
        Funkcjonalność jest w trakcie przygotowywania
      </Typography>
    </Box>
  )
}

export default CommingSoon
