import SizeWindow from '../../molecules/SizeWindow/SizeWindow'
import { Box, Grid, Typography } from '@mui/material'
import imageAbout1 from '../../../assets/images/company/3.jpg'
import imageAbout2 from '../../../assets/images/company/4.jpg'
import burger from '../../../assets/images/burger.png'
import costAndQuality from '../../../assets/images/costAndQuality.png'
import { useEffect, useState } from 'react'
import { getAllDataFromPath } from '../../../firebase'

const AboutUs = () => {
  const [partners, setPartners] = useState(null)
  const style = {
    root: {
      marginTop: '200px',
      marginBottom: '200px',
    },
    img: {
      width: '100%',
      minHeight: '500px',
      objectFit: 'cover',
    },
    title: {
      color: '#231E41',
      fontSize: '40px',
      fontWeight: '600',
      lineHeight: '110%',
      letterSpacing: '-2px',
      marginBottom: '30px',
    },
    textJustify: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px',
      textAlign: 'justify',
    },
    text: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px'
    },
    circle1: {
      width: '25px',
      height: '25px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      top: '0',
      left: '-20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))',
    },
    circle2: {
      width: '50px',
      height: '50px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-20px',
      left: '50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))',
    },
    circle3: {
      width: '80px',
      height: '80px',
      border: '30px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-50px',
      right: '-50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
    },
    circle4: {
      width: '50px',
      height: '50px',
      border: '20px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '50px',
      left: '20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))',
    },
  }
  useEffect(() => {
    getAllDataFromPath('partners').then((res) => {
      if (res) {
        const temp = []
        res?.forEach(el => {
          if (el.data.inGramWGastro === true) {
            temp.push(el)
          }
        })
        setPartners(temp)
      }
    })
  }, [])
  return (
   <>
     <SizeWindow>
       <Box sx={style.root}>
         <Grid container spacing={12}>
           <Grid item md={6} xs={12}>
             <img src={imageAbout1} alt="about us" style={style.img} />
           </Grid>
           <Grid item md={6} xs={12}>
             <Typography sx={style.title}>
               Staramy się robić więcej niż oczekujesz
             </Typography>
             <Typography sx={style.textJustify}>
               Organizacja <b>Gram w gastro</b> działa na rzecz promocji swoich partnerów w branży gastronomicznej poprzez oferowanie szeregu korzyści i udogodnień. Jednym z głównych celów organizacji jest zwiększenie widoczności i zasięgu restauracji, które dołączają do programu partnerskiego. Aby to osiągnąć, organizacja umożliwia korzystanie z aplikacji webowej i mobilnej, co pozwala klientom na łatwe i szybkie zamówienia z menu dostępnych restauracji.
             </Typography>
             <Typography sx={style.textJustify}>
               Dodatkowo, partnerzy organizacji otrzymują specjalne zniżki u dostawców jedzenia, co pozwala na obniżenie kosztów prowadzenia biznesu oraz oferowanie bardziej konkurencyjnych cen swoim klientom. Dzięki temu partnerzy organizacji mogą skupić się na rozwijaniu swojego menu oraz jakości obsługi, zwiększając tym samym swoją atrakcyjność w oczach klientów.
             </Typography>
           </Grid>
         </Grid>
       </Box>
     </SizeWindow>
     {/* <Cookers /> */}
     <SizeWindow background="#F9FAFC">
       <Box sx={style.root}>
         <Grid container spacing={12}>
           <Grid item md={6} xs={12}>
             <Typography sx={style.title}>
               Liczy się dla nas partnerstwo
             </Typography>
             <Typography sx={style.textJustify}>
               Każdy z partnerów posiada również dostęp do własnego panelu, który pozwala na zarządzanie oferowanymi zniżkami. To oznacza, że partnerzy organizacji mogą łatwo dostosować swoje oferty do potrzeb swoich klientów, np. poprzez oferowanie zniżek na konkretny produkt lub kategorię produktów.
             </Typography>
             <Typography sx={style.textJustify}>
               Ponadto, aplikacja <b>Gram w gastro</b> oferuje również ogłoszenia o pracę w restauracjach partnerów. Dzięki temu osoby poszukujące pracy w branży gastronomicznej mogą łatwo znaleźć oferty pracy, które odpowiadają ich kwalifikacjom oraz preferencjom.
             </Typography>
             <Typography sx={style.textJustify}>
               Wszystkie te działania organizacji <b>Gram w gastro</b> pozwalają na stworzenie silnego partnerstwa w branży gastronomicznej, które skupia się na wzajemnej współpracy i wzajemnym wsparciu. Dzięki temu, organizacja przyczynia się do rozwoju i zwiększenia konkurencyjności branży gastronomicznej, a jej partnerzy zyskują dostęp do narzędzi, które pozwalają na łatwiejsze i bardziej efektywne prowadzenie swojego biznesu.
             </Typography>
           </Grid>
           <Grid item md={6} xs={12}>
             <img src={imageAbout2} alt="about us" style={style.img} />
           </Grid>
         </Grid>
         <Box sx={{ marginTop: '80px' }}>
           <Grid container spacing={8}>
              {
                partners?.map((el, index) => {
                  const dataPartner = el?.data
                  if (dataPartner?.see) {
                    return (
                        <Grid key={index} item md={2} xs={6}>
                          <Box onClick={() => window.open(dataPartner?.website, '_blank')} className="sizeAnimation" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', height: '150px' }}>
                            <img src={dataPartner?.logo} alt={dataPartner?.company} style={{ width: '100%' }} />
                          </Box>
                        </Grid>
                    )
                  } else return ''
                })
              }
           </Grid>
         </Box>
       </Box>
     </SizeWindow>
     <SizeWindow>
       <Box sx={style.root}>
         <Grid container spacing={12}>
           <Grid item md={6} xs={12}>
             <img src={burger} alt="serce" style={{ width: '100%' }} />
           </Grid>
           <Grid item md={6} xs={12}>
             <Typography sx={style.title}>
               Najważniejsze są składniki
             </Typography>
             <Typography sx={style.textJustify}>
               Z dumą informujemy, że każdy nasz współpracujący partner ma możliwość skorzystania z ekskluzywnych zniżek u naszych renomowanych dostawców. Taka inicjatywa ma na celu nie tylko zmniejszenie kosztów operacyjnych ich restauracji, ale przede wszystkim skoncentrowanie się na najwyższej jakości składnikach.
             </Typography>
             <Typography sx={style.textJustify}>
               Dzięki temu wyjątkowemu programowi zniżek, nasi partnerzy mogą cieszyć się korzystnymi cenami zakupu, które bezpośrednio wpływają na efektywność finansową ich biznesów. Dostarczając im atrakcyjne stawki, wspieramy naszych partnerów w redukcji kosztów operacyjnych, co pozwala im utrzymać konkurencyjne ceny dla swoich klientów.
             </Typography>
             <Typography sx={style.textJustify}>
               Jednak nasze działania nie kończą się na aspekcie finansowym. Skoncentrowaliśmy się również na zapewnieniu najwyższej jakości składników. Dzięki naszym zniżkom, partnerzy mogą bez obaw inwestować w składniki najwyższej jakości, które stanowią fundament ich wyśmienitych potraw. Składniki te nie tylko wpływają na smak i atrakcyjność dań, ale również pozwalają na budowanie lojalności klientów i wzmacnianie wizerunku marki.
             </Typography>
           </Grid>
         </Grid>
       </Box>
     </SizeWindow>
     <SizeWindow background="#F9FAFC">
       <Box sx={style.root}>
         <Grid container spacing={12}>
           <Grid item md={6} xs={12}>
             <Typography sx={style.title}>
               Optymalizacja kosztów i jakość
             </Typography>
             <Typography sx={style.textJustify}>
               Jest dla nas priorytetem zapewnienie naszym partnerom wyjątkowego doświadczenia, dlatego skrupulatnie dobieramy dostawców, którzy nie tylko oferują konkurencyjne ceny, ale również gwarantują najwyższą jakość swoich produktów. Dzięki temu nasi partnerzy mają pewność, że korzystają z niezawodnej sieci dostawców, której można zaufać.
             </Typography>
             <Typography sx={style.textJustify}>
               Ponadto, dla zapewnienia pełnego wykorzystania korzyści z programu zniżek, zapewniamy naszym partnerom klarowną komunikację i wsparcie w korzystaniu z tych możliwości. Wierzymy, że transparentna współpraca i wspólny sukces są kluczem do długotrwałych relacji biznesowych.
             </Typography>
             <Typography sx={style.textJustify}>
               Jesteśmy dumni z naszej inicjatywy udostępniania zniżek u dostawców dla naszych partnerów, ponieważ wierzymy, że wspólnie możemy osiągnąć wyższe cele i podnieść jakość gastronomii na nowy poziom.
             </Typography>
           </Grid>
           <Grid item md={6} xs={12}>
             <img src={costAndQuality} alt="serce" style={{ width: '100%' }} />
           </Grid>
         </Grid>
       </Box>
     </SizeWindow>
   </>
  )
}

export default AboutUs
