import NamePage from '../../components/molecules/NamePage/NamePage'
import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getElementById, updateOrCreateDocument } from '../../firebase'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {
  FacebookOutlined,
  FmdGoodOutlined,
  RequestPageOutlined,
  StoreOutlined
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'

const JobApplyPage = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [jobId] = useState(props?.location?.location?.pathname?.split('/')[2])
  const style = {
    root: {},
    textWithIcon: {
      marginTop: '20px',
      display: 'flex',
      background: '#4267B2',
      color: 'white !important',
      padding: '10px',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    boxBig: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: '20px',
      paddingRight: '20px',
      maxWidth: '100%',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      marginRight: '30px',
    },
    boxIconBox: {
      padding: '20px',
      marginRight: '20px',
      background: '#F9FAFC',
      borderRadius: '5px',
    },
    boxIcon: {
      fill: '#074F1F',
    },
    title: {
      fontWeight: 'bold',
      marginBottom: '20px',
      marginTop: '20px',
    },
  }
  const [data, setData] = useState(null)
  const formData = {
    elements: [
      {
        type: 'text',
        name: 'firstName',
        label: 'imię',
        validationType: 'string',
      },
      {
        type: 'text',
        name: 'lastName',
        label: 'nazwisko',
        validationType: 'string',
      },
      {
        type: 'email',
        name: 'email',
        label: 'Email',
        validationType: 'string',
      },
      {
        type: 'text',
        name: 'phone',
        label: 'Telefon',
        validationType: 'string',
      },
      {
        type: 'select',
        name: 'student',
        items: [{ value: 'Tak', name: 'Tak' }, { value: 'Nie', name: 'Nie' }],
        label: 'Czy jesteś studentem?',
        validationType: 'string',
      },
      {
        type: 'files',
        name: 'cv',
        sizeFileLimit: 20000000,
        buttonName: 'Wgraj swoje CV',
        pathName: `/jobs/${jobId}`,
        validationType: 'string',
      },
      {
        type: 'checkbox',
        name: 'regulations',
        label: 'Akceptacja regulaminów ze strony internetowej oraz polityki prywatności',
        validationType: 'string',
      },
      {
        type: 'button',
        value: 'Aplikuj',
      },
    ],
  }
  const handleShare = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank')
  }
  const handleApply = (e) => {
    const tempData = data
    const oldUsers = data?.users || []
    oldUsers.push(e)
    tempData.users = oldUsers
    updateOrCreateDocument('jobs', jobId, tempData).then((res) => {
      if (res) {
        enqueueSnackbar('Udało się poprawnie zaaplikować na to stanowisko.', { variant: 'success' })
      }
    })
  }
  useEffect(() => {
      getElementById(jobId, 'jobs').then((res) => {
        if (res) {
          setData(res[0])
        }
      })
  }, [jobId])
  return (
    <SizeWindow>
      <NamePage name={data?.position}>
        <Grid container spacing={6}>
          <Grid item md={8} xs={12}>
            <Box sx={style.boxBig}>
              <Box sx={style.box}>
                <Box sx={style.boxIconBox}>
                  <FmdGoodOutlined sx={style.boxIcon}/>
                </Box>
                <Typography>{data?.city}</Typography>
              </Box>
              <Box sx={style.box}>
                <Box sx={style.boxIconBox}>
                  <StoreOutlined sx={style.boxIcon}/>
                </Box>
                <Typography>{data?.company}</Typography>
              </Box>
              <Box sx={style.box}>
                <Box sx={style.boxIconBox}>
                  <RequestPageOutlined sx={style.boxIcon}/>
                </Box>
                <Typography>{data?.docs}</Typography>
              </Box>
            </Box>
            <Typography sx={style.title}>Opis</Typography>
            <Typography sx={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: data?.description }} />
            {
              data?.requirements?.length > 0 && (
                <>
                  <Typography sx={style.title}>Wymagania</Typography>
                  {
                    data?.requirements?.map((el, index) => (
                      <Typography key={index}>• {el}</Typography>
                    ))
                  }
                </>
              )
            }
            {
              data?.expectations?.length > 0 && (
                <>
                  <Typography sx={style.title}>Oczekiwania</Typography>
                  {
                    data?.expectations?.map((el, index) => (
                      <Typography key={index}>• {el}</Typography>
                    ))
                  }
                </>
              )
            }
            {
              data?.offer?.length > 0 && (
                <>
                  <Typography sx={style.title}>Oferujemy</Typography>
                  {
                    data?.offer?.map((el, index) => (
                      <Typography key={index}>• {el}</Typography>
                    ))
                  }
                </>
              )
            }
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGenerator data={formData} submit={(e) => handleApply(e)} />
            <Button
              fullWidth
              variant="contained"
              sx={style.textWithIcon}
              onClick={() => handleShare()}
            >
              <Typography sx={{ marginRight: '10px', color: 'white' }}>Udostępnij ofertę </Typography>
              <FacebookOutlined sx={{ fill: 'white' }} />
            </Button>
          </Grid>
        </Grid>
      </NamePage>
    </SizeWindow>
  )
}

export default JobApplyPage
