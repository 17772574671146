export default {
  getUrlFromPathname: (url) => {
    return `${process.env.REACT_APP_FIREBASE_STORAGE_LINK}${process.env.REACT_APP_STORAGE_BUCKET}/o/${url.replace('/', '%2F')}`
  },
  getPathNameFromUrl: (url, storage = 'users', version) => {
    return url ? url.replace(`${process.env.REACT_APP_FIREBASE_STORAGE_LINK}${process.env.REACT_APP_STORAGE_BUCKET}/o/`, '').split('?')[0].replace('%2F', '/') : ''
  },
  decodeFile: (file) => {
    return decodeURIComponent(file.replace(`${process.env.REACT_APP_FIREBASE_STORAGE_LINK}${process.env.REACT_APP_STORAGE_BUCKET_IMPORT}/o/`, '').split('?')[0])
  }
}
