import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { createOrUpdatePartnersForm, deleteFromPath, getAllElementsByName } from '../../firebase'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router'

const AdminPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    box: {
      padding: '10px 20px',
      boxShadow: '2px 2px 20px 1px rgba(0,0,0,0.15)',
      marginBottom: '30px',
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    description: {
      fontSize: '16px',
      lineHeight: '110%',
      fontWeight: '300',
      marginTop: '5px',
    },
    element: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid rgba(7,79,31)',
      background: '#ffeaea',
    },
    elementOK: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid rgba(7,79,31)',
      background: '#eaffea',
    },
    button: {
      marginTop: '5px',
      marginRight: '10px',
    },
    buttonError: {
      marginTop: '5px',
      marginRight: '10px',
      background: '#cb0000',
    },
  }
  const setInGramWGastro = (data, id) => {
    data.inGramWGastro = true
    createOrUpdatePartnersForm(data, id).then((res) =>
      res && updatePartners().then(() => enqueueSnackbar('Dodano poprawnie.', { variant: 'success' })))
  }
  const setInNotGramWGastro = (data, id) => {
    data.inGramWGastro = false
    createOrUpdatePartnersForm(data, id).then((res) =>
      res && updatePartners().then(() => enqueueSnackbar('Dodano poprawnie.', { variant: 'success' })))
  }
  const updatePartners = () => getAllElementsByName('partners').then((res) => res && setData(res))
  useEffect(() => {
    updatePartners()
  }, [])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item md={8} xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={style.title}>
                  Zgłoszenia z formularza partnerów
                </Typography>
                <Typography sx={style.description}>
                  Lista partnerów, którzy zgłosili się z formularza dostępnego na podstronie (Zostań Partnerem) oraz możliwość ich oznaczania lub usunięcia.
                </Typography>
              </Grid>
              <Grid item md={12}>
                {
                  data?.length
                    ? data?.map((el, index) => {
                      return (
                        <Box key={index} sx={el.inGramWGastro ? style.elementOK : style.element}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <img src={el.logo} alt={el.company + ' logo'} style={{ width: '80px', maxHeight: '80px', height: 'auto', marginRight: '20px' }} />
                            </Box>
                            <Box>
                              <Typography sx={{ marginBottom: '5px' }}><b>Firma:</b> {el.company}, <b>Osoba kontaktowa:</b> {el.name}</Typography>
                              <Typography sx={{ marginBottom: '10px' }}><b>Email:</b> {el.email}, <b>Telefon:</b> {el.phone}</Typography>
                            </Box>
                          </Box>
                          <Box>
                            { !el.inGramWGastro && <Button variant="contained" sx={style.buttonError} onClick={() => setInGramWGastro(el, el.id)}>Status nieprzyjęta</Button> }
                            { el.inGramWGastro && <Button variant="contained" sx={style.button} onClick={() => setInNotGramWGastro(el, el.id)}>Status przyjęta</Button> }
                          </Box>
                        </Box>
                      )
                    })
                    : (
                      <Typography>
                        Brak zgłoszonych partnerów
                      </Typography>
                    )
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={style.title}>
                  Oferty pracy
                </Typography>
                <Typography sx={style.description}>
                  Możliwość zobaczenia listy ofert pracy, ich edycja oraz dodanie nowej oferty pracy na wybranego partnera.
                </Typography>
                <Button fullWidth sx={{ marginTop: '20px', marginBottom: '10px' }} variant="contained" onClick={() => history.push('/jobAdd')}>Dodaj nową ofertę</Button>
                <Button fullWidth sx={{ marginBottom: '10px', background: '#0f57d9' }} variant="contained" onClick={() => history.push('/jobsNeedList')}>Lista potrzebnych ofert pracy</Button>
                <Button fullWidth sx={{ marginBottom: '10px', background: '#0f57d9' }} variant="contained" onClick={() => history.push('/jobsList')}>Lista ofert pracy</Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={style.title}>
                  Rekrutacja
                </Typography>
                <Typography sx={style.description}>
                  Możliwość sprawdzenia wybranego kandydata i oznaczenie jego doświadczenia i umiejętności na podstawie wybranego stanowiska na które rekrutuje.
                </Typography>
                <Button fullWidth sx={{ marginTop: '20px', marginBottom: '10px' }} variant="contained">Dodaj kandydata</Button>
                <Button fullWidth sx={{ marginBottom: '10px' }} variant="contained">Ocena kandydata</Button>
                <Button fullWidth sx={{ marginBottom: '10px', background: '#0f57d9' }} variant="contained" onClick={() => history.push('/recruitmentList')}>Lista kandydatów</Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={style.title}>
                  Towary
                </Typography>
                <Typography sx={style.description}>
                  Dodanie rodzajów zamawiany towarów, ich edycja oraz usuwania. Historia cen towarów, u konkretnych dostawców.
                </Typography>
                <Button fullWidth sx={{ marginBottom: '10px', background: '#0f57d9' }} variant="contained" onClick={() => history.push('/commodityList')}>Lista towarów</Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={style.title}>
                  Dostawcy
                </Typography>
                <Typography sx={style.description}>
                  Możliwość dodania dostawcy oraz podłączenie pod niego jakie rodzaje towarów dostarcza i w jakiej cenie.
                </Typography>
                <Button fullWidth sx={{ marginTop: '20px', marginBottom: '10px' }} variant="contained">Dodaj dostawcę</Button>
                <Button fullWidth sx={{ marginBottom: '10px' }} variant="contained">Dodaj towary dostawcy</Button>
                <Button fullWidth sx={{ marginBottom: '10px', background: '#0f57d9' }} variant="contained" onClick={() => history.push('/supplierList')}>Lista dostawców</Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={style.title}>
                  Rezerwacje
                </Typography>
                <Typography sx={style.description}>
                  Dodanie rezerwacji stolika w wybranej restauracji oraz modyfikacja również istniejącej.
                </Typography>
                <Button fullWidth sx={{ marginTop: '20px', marginBottom: '10px' }} variant="contained">Dodaj rezerwację</Button>
                <Button fullWidth sx={{ marginBottom: '10px', background: '#0f57d9' }} variant="contained">Lista rezerwacji</Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={style.title}>
                  Blog
                </Typography>
                <Typography sx={style.description}>
                  Możliwość zarządzanie postami (dodawaniem, usuwanie i edycją).
                </Typography>
                <Button fullWidth sx={{ marginTop: '20px', marginBottom: '10px' }} variant="contained" onClick={() => history.push('/postAdd')}>Dodaj posta</Button>
                <Button fullWidth sx={{ marginBottom: '10px', background: '#0f57d9' }} variant="contained" onClick={() => history.push('/postsList')}>Lista postów</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default AdminPage
