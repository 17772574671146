export default {
  getAll: () => {
    return [
      {
        value: 'Kelner',
        name: 'Kelner',
      },
      {
        value: 'Dostawca',
        name: 'Dostawca',
      },
      {
        value: 'Pracownik Foodtruck',
        name: 'Pracownik Foodtruck',
      },
      {
        value: 'Obsługa klienta',
        name: 'Obsługa klienta',
      },
      {
        value: 'Pizzerman',
        name: 'Pizzerman',
      },

      {
        value: 'Kierowca',
        name: 'Kierowca',
      },

      {
        value: 'Kucharz',
        name: 'Kucharz',
      },
    ]
  }
}
