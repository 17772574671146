import React, { useState } from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import logo from '../../../assets/configFiles/logo.png'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import { Button, Typography, Menu, MenuItem } from '@mui/material'

const NavigationPreview = (props) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const style = {
    nav: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: `${BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 100px)'}`,
      margin: '0 auto',
      height: '90px',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: BreakpointsHelper.isMobile() ? 15 : 50,
      paddingLeft: BreakpointsHelper.isMobile() ? 15 : 50,
      zIndex: 999,
      borderBottom: '1px solid #e8e9eb',
    },
    logo: {
      height: '80px',
      cursor: 'pointer'
    },
    logoOpen: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '35px',
      position: 'relative',
      left: '-5px',
      bottom: '-3px'
    },
    buttons: {
      display: 'flex',
      alignItems: 'center'
    },
    burgerOpen: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      right: '-5px',
      bottom: '-2px',
      marginBottom: '30px'
    },
    elementNav: {
      fontSize: '14px',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '40px',
      cursor: 'pointer',
      padding: '0 30px',
    },
    menuRight: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    link: {
      textDecoration: 'none',
      marginLeft: '30px',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '14px',
      color: '#231E41',
      marginBottom: BreakpointsHelper.isMobile() ? '10px' : '0',
      fontWeight: '400',
      transition: '.3s ease-in-out',
      cursor: 'pointer',
    },
    linkDrop: {
      textTransform: 'initial',
      textDecoration: 'none',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '14px',
      color: '#231E41',
      marginBottom: BreakpointsHelper.isMobile() ? '10px' : '0',
      fontWeight: '400',
      transition: '.3s ease-in-out',
      cursor: 'pointer',
    }
  }
  const openLink = (link) => {
    if (link) {
      if (BreakpointsHelper.isMobile()) setOpen(false)
      props.location.history.push(link)
    }
    handleClose()
  }
  const generateMenu = () => {
    return (
      <>
        {/* <Typography sx={style.link} onClick={() => openLink('/orders')}>Zamów jedzenie</Typography> */}
        {/* <Typography sx={style.link} onClick={() => openLink('/training')}>Szkolenia</Typography> */}
        <Button
          sx={{ marginLeft: '20px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          variant="text"
          onClick={() => openLink('/prices')}
        >
          <Typography sx={style.linkDrop}>Negocjacje cenowe</Typography>
        </Button>
        <Button
          sx={{ marginLeft: '20px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          variant="text"
          onClick={() => openLink('/partners')}
        >
          <Typography sx={style.linkDrop}>Zostań partnerem</Typography>
        </Button>
        <Button
          sx={{ marginLeft: '20px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          variant="text"
          id="basic-button"
          aria-controls={openMenu ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={handleClick}
        >
          <Typography sx={style.linkDrop}>Nasze usługi</Typography>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => openLink('/services')}>Co mamy w ofercie?</MenuItem>
          <MenuItem onClick={() => openLink('/events')}>Wydarzenia</MenuItem>
          <MenuItem onClick={() => openLink('/bookkeeping')}>Księgowość</MenuItem>
          <MenuItem onClick={() => openLink('/training')}>Szkolenia</MenuItem>
        </Menu>
        <Button
          sx={{ marginLeft: '20px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          variant="text"
          onClick={() => openLink('/jobs')}
        >
          <Typography sx={style.linkDrop}>Oferty pracy</Typography>
        </Button>
        <Button
          sx={{ marginLeft: '20px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          variant="text"
          onClick={() => openLink('/blog')}
        >
          <Typography sx={style.linkDrop}>Blog</Typography>
        </Button>
        <Button
          sx={{ marginLeft: '20px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          variant="text"
          onClick={() => openLink('/fundation')}
        >
          <Typography sx={style.linkDrop}>Fundacja</Typography>
        </Button>
        <Button
          sx={{ marginLeft: '20px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          variant="text"
          onClick={() => openLink('/contact')}
        >
          <Typography sx={style.linkDrop}>Kontakt</Typography>
        </Button>
      </>
    )
  }
  return (
    <div style={style.nav} className="navigation">
      <Box sx={{ maxWidth: '1290px', margin: '0 auto', width: '100%', display: 'flex', alignItems: 'center' }}>
        <img src={logo} onClick={() => openLink('/home')} alt={'logo'} style={style.logo}/>
        <Box sx={style.menuRight}>
          {
            BreakpointsHelper.isMobile()
              ? (
                <div style={style.burger} onClick={() => setOpen(!open)}>
                  <MenuIcon style={style.menu}/>
                </div>
              )
              : generateMenu()
          }
          {
            open && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '999',
                  background: 'white',
                  top: '90px',
                  left: '0',
                  height: 'calc(100vh - 90px)',
                  width: 'calc(100% - 40px)',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px'
                }}
              >
                {generateMenu()}
              </Box>
            )
          }
        </Box>
      </Box>
    </div>
  )
}

export default NavigationPreview
