import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'
import NamePage from '../../components/molecules/NamePage/NamePage'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getAllDataFromPath } from '../../firebase'
import { useHistory } from 'react-router'

const BlogPage = () => {
  const history = useHistory()
  const [posts, setPosts] = useState(null)
  useEffect(() => {
    getAllDataFromPath('posts').then((res) => {
      if (res) {
        setPosts(res)
      }
    })
  }, [])
  return (
    <SizeWindow>
      <NamePage name="Blog">
        <Grid container spacing={4}>
            {
              posts?.length
                ? posts?.map((data, index) => {
                  const el = data?.data
                  return (
                    <Grid item md={4} key={index}>
                      <Box sx={{ cursor: 'pointer' }} onClick={() => history.push(`/post/${data.id}`)}>
                        <img src={el.image} style={{ width: '100%', height: '300px', objectFit: 'cover', marginBottom: '10px' }} alt={el.title} />
                        <Typography sx={{ marginBottom: '5px', textTransform: 'uppercase', fontSize: '20px', textAlign: 'center' }}>{el.title}</Typography>
                      </Box>
                    </Grid>
                  )
                })
                : (
                  <Typography>
                    Brak postów
                  </Typography>
                )
            }
        </Grid>
      </NamePage>
    </SizeWindow>
  )
}

export default BlogPage
