import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const EditorText = ({ defaultValue, onChange, label }) => {
  const [value, setValue] = useState(defaultValue || '')
  return (
    <Editor
      placeholder={label}
      editorState={value}
      onEditorStateChange={(e) => {
        setValue(e)
        onChange(value)
      }}
    />
  )
}

export default EditorText
