import { Box, Button, Grid, Typography } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { useEffect, useState } from 'react'
import { createOrUpdateCommodity, getAllDataFromPath, getAllElementsByName } from '../../firebase'
import { useSnackbar } from 'notistack'
import RolesHelper from '../../helpers/RolesHelper/RolesHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import Dialog from '../../components/atoms/Dialog/Dialog'

const CommodityListPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const isSuperAdmin = RolesHelper.isSuperAdmin()
  const today = new Date()
  const todayYear = today.getFullYear()
  const todayMonth = (today.getMonth() + 1).toString().length > 1 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)
  const todayDay = (today.getDate()).toString().length > 1 ? today.getDate() : '0' + today.getDate()
  const [data, setData] = useState(null)
  const [suppliers, setSuppliers] = useState(null)
  const [currentProduct, setCurrentProduct] = useState(null)
  const [open, setOpen] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    box: {
      padding: '10px 20px',
      boxShadow: '2px 2px 20px 1px rgba(0,0,0,0.15)',
      marginBottom: '30px',
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    description: {
      fontSize: '16px',
      lineHeight: '110%',
      fontWeight: '300',
      marginTop: '5px',
    },
    element: {
      padding: '20px',
      border: '1px solid rgba(7,79,31,.2)',
    },
  }
  const suppliersForm = {
    elements: [
      {
        type: 'select',
        name: 'name',
        label: 'Wybierz dostawcę',
        items: suppliers,
        validationType: 'string'
      },
      {
        type: 'select',
        name: 'category',
        label: 'Wybierz kategorię',
        items: [
          { name: 'Sery', value: 'Sery' },
          { name: 'Wędliny', value: 'Wędliny' },
          { name: 'Produkty mrożone', value: 'Produkty mrożone' },
          { name: 'Mięsa i ryby', value: 'Mięsa i ryby' },
          { name: 'Warzywa i owoce', value: 'Warzywa i owoce' },
          { name: 'Nabiał', value: 'Nabiał' },
          { name: 'Oliwy i oleje', value: 'Oliwy i oleje' },
          { name: 'Przyprawy', value: 'Przyprawy' },
          { name: 'Alkohole', value: 'Alkohole' },
          { name: 'Napoje', value: 'Napoje' },
        ],
        validationType: 'string'
      },
      {
        name: 'unity',
        label: 'Jednostka towaru',
        type: 'select',
        items: [
          { name: 'kg', value: 'kg' },
          { name: 'dg', value: 'dg' },
          { name: 'g', value: 'g' },
          { name: 'l', value: 'l' },
          { name: 'ml', value: 'ml' },
          { name: 'szt.', value: 'szt.' },
        ],
        validationType: 'string',
      },
      {
        name: 'count',
        label: 'Ilość towaru',
        type: 'number',
        validationType: 'number',
      },
      {
        name: 'price',
        label: 'Określ cenę',
        type: 'text',
        validationType: 'string',
      },
      {
        name: 'date',
        label: 'Data ceny',
        type: 'date',
        value: `${todayYear}-${todayMonth}-${todayDay}`,
        validationType: 'string',
      },
      {
        type: 'button',
        value: 'Przypisz dostawcę',
      }
    ]
  }
  const addCommodityForm = {
    elements: [
      {
        name: 'name',
        type: 'text',
        label: 'Nazwa towaru',
        validateType: 'string',
      },
      {
        type: 'button',
        value: 'Dodaj towar',
      },
    ]
  }
  const getSuppliers = () => {
    getAllDataFromPath('suppliers').then((res) => {
      if (res) {
        const tempData = []
        res?.forEach(el => {
          const element = el.data
          tempData.push({ name: element.name, value: el.id })
        })
        setSuppliers(tempData)
      }
    })
  }
  const assignSupplier = (e) => {
    let tempName = null
    suppliers?.forEach(el => {
      if (el.value === e.name) {
        tempName = el
      }
    })
    e.name = tempName
    const dataProduct = currentProduct
    if (dataProduct?.suppliers?.length === 0 || !dataProduct?.suppliers) {
      const tempArray = []
      tempArray.push(e)
      dataProduct.suppliers = tempArray
    } else {
      dataProduct?.suppliers.push(e)
    }
    createOrUpdateCommodity(dataProduct, currentProduct.id).then((res) => {
      if (res) {
        setOpen(!open)
        handleData()
        handleData().then(() => enqueueSnackbar('Dodano dostawcę poprawnie.', { variant: 'success' }))
      }
    })
  }
  const handleCommodity = (e) => {
    createOrUpdateCommodity(e).then((res) => {
      if (res) {
        handleData()
        enqueueSnackbar('Dodano poprawnie.', { variant: 'success' })
      }
    })
  }
  const handleData = () => getAllElementsByName('commodity').then((res) => res && setData(res))
  useEffect(() => {
      handleData()
      getSuppliers()
  }, [])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={style.box}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography sx={style.title}>
                    Lista towarów
                  </Typography>
                  <Typography sx={style.description}>
                    Możliwość edycji i usuwania towarów. Historia cen towarów, u konkretnych dostawców oraz dopisywanie towarów do danego dostawcy lub dostawców.
                  </Typography>
                  <Box sx={{ maxWidth: '320px' }}>
                    <FormGenerator data={addCommodityForm} submit={(e) => handleCommodity(e)} />
                  </Box>
                </Grid>
              {
                data?.map((el, index) => {
                  return (
                    <Grid key={index} item md={4} xs={12}>
                      <Box sx={style.element}>
                        <Typography><b>Rodzaj towaru:</b> {el.name}</Typography>
                        {
                          el?.suppliers?.length > 0 && (
                              <>
                                <Typography><b>Aktulany dostawca: </b>{el.suppliers[0]?.name?.name}</Typography>
                                <Typography><b>Ilość: </b>{el.suppliers[0]?.count} {el.suppliers[0]?.unity}</Typography>
                                <Typography><b>Cena: </b>{el.suppliers[0]?.price} zł</Typography>
                                <Typography><b>Ustalona kwota: </b>{el.suppliers[0]?.date}</Typography>
                              </>
                          )
                        }
                        {
                          isSuperAdmin && (
                            <>
                              <Button
                                  sx={{ marginTop: '10px', marginRight: '10px' }}
                                  variant="contained"
                                  onClick={() => {
                                    setCurrentProduct(el)
                                    setOpen(!open)
                                  }}
                              >
                                Przypisz dostawcę
                              </Button>
                            </>
                          )
                        }
                      </Box>
                    </Grid>
                  )
                })
              }
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {
          open && (
              <Dialog open={open} title="Przypisanie dostawcy do produktu" content={<FormGenerator data={suppliersForm} submit={(e) => assignSupplier(e)} />} onClose={() => setOpen(false)} />
          )
      }
    </div>
  )
}

export default CommodityListPage
