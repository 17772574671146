import NamePage from '../../components/molecules/NamePage/NamePage'
import { Box, Grid, Typography } from '@mui/material'
import imageAbout0 from '../../assets/images/380990640_825715922890056_1009078058640859410_n.jpg'
import imageAbout1 from '../../assets/images/380888242_825705192891129_6741155042931758279_n.jpg'
import imageAbout2 from '../../assets/images/380938161_825705486224433_2152542655040495504_n.jpg'
import SizeWindow from '../../components/molecules/SizeWindow/SizeWindow'

const EventsPage = () => {
  const style = {
    root: {
      marginTop: '200px',
      marginBottom: '200px'
    },
    img: {
      width: '100%',
      minHeight: '500px',
      objectFit: 'cover'
    },
    title: {
      color: '#231E41',
      fontSize: '40px',
      fontWeight: '600',
      lineHeight: '110%',
      letterSpacing: '-2px',
      marginBottom: '30px'
    },
    textJustify: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px',
      textAlign: 'justify'
    },
    text: {
      fontSize: '18px',
      color: '#6F6F87',
      marginBottom: '10px'
    },
    circle1: {
      width: '25px',
      height: '25px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      top: '0',
      left: '-20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))'
    },
    circle2: {
      width: '50px',
      height: '50px',
      background: '#074F1F',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-20px',
      left: '50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.7))'
    },
    circle3: {
      width: '80px',
      height: '80px',
      border: '30px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '-50px',
      right: '-50px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))'
    },
    circle4: {
      width: '50px',
      height: '50px',
      border: '20px solid white',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '50px',
      left: '20px',
      zIndex: 3,
      filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))'
    }
  }
  return (
    <NamePage name="Wydarzenia">
      <SizeWindow>
        <Box sx={style.root}>
          <Grid container spacing={12}>
            <Grid item md={6} xs={12}>
              <img src={imageAbout0} alt="about us" style={style.img}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={style.title}>
                KOSZALIN SHAKE OFF - wspominamy z niekłamaną radością
              </Typography>
              <Typography sx={style.textJustify}>
                Dla tych, którzy pasjonują się światem koktajli, barmańskim rzemiosłem i wyjątkowym jedzeniem, to było
                miejsce, w którym naprawdę trzeba było być. Ale nie tylko dla nich!
              </Typography>
              <Typography sx={style.textJustify}>
                <ul>
                  <li>
                    <b>Sprzęt Barmański od Suma Service:</b> Każdy zawodnik czuł się jak w domu, dzięki profesjonalnemu
                    sprzętowi od jednego z naszych głównych partnerów, Suma Service.
                  </li>
                  <li>
                    <b>Grillowe Czary od Lord of Taste:</b> Kto mógł się oprzeć aromatowi grillowanych potraw? Z
                    pewnością nie
                    my! A kiedy dodasz do tego limitowaną kartę koktajli od Lord of Taste pod hasłem Taste is a
                    journey, to otrzymujesz kombo idealne na ciepłe, letnie wieczory.
                  </li>
                  <li>
                    <b>Mistrzowie Grillowi:</b> Nasi grill masterzy dali z siebie wszystko, tworząc specjalne menu na tę
                    okazję. Każdy kęs to prawdziwa uczta dla podniebienia.
                  </li>
                  <li>
                    <b>Fotobudka 360 Koszalin:</b> Chcieliśmy, abyście mieli piękną pamiątkę z tego wydarzenia. Dzięki
                    fotobudce 360 stopni każdy mógł poczuć się jak gwiazda i uwiecznić te chwile w niezwykły sposób.
                  </li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={style.root}>
          <Grid container spacing={12}>
            <Grid item md={6} xs={12}>
              <Typography sx={style.title}>
                Mistrzostwa Szybkiego Barmana
              </Typography>
              <Typography sx={style.textJustify}>
                W środku burzliwego dnia pełnego atrakcji, jednym z najbardziej oczekiwanych punktów programu były
                Mistrzostwa Szybkiego Barmana. Ta konkurencja to prawdziwy test umiejętności, precyzji i szybkości dla
                każdego uczestniczącego barmana.
              </Typography>
              <Typography sx={style.textJustify}>
                <ul>
                  <li>
                    <b>Menu na czas:</b> Każdy z barmanów otrzymywał listę napojów do przygotowania. Wybór nie był
                    przypadkowy - menu składało się z klasycznych koktajli, ale także z trudniejszych do wykonania
                    mieszanków.
                  </li>
                  <li>
                    <b>Przygotowanie:</b> Uczestnicy musieli użyć odpowiednich składników, narzędzi i technik, aby
                    idealnie
                    odtworzyć każdy z napojów.
                  </li>
                  <li>
                    <b>Wyścig z czasem:</b> Chociaż dokładność była kluczem, to szybkość miała tutaj ogromne znaczenie.
                    Wygrywał ten, kto w najkrótszym czasie przygotował całe menu, nie popełniając błędów.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <img src={imageAbout2} alt="about us" style={style.img}/>
            </Grid>
          </Grid>
        </Box>
        <Box sx={style.root}>
          <Grid container spacing={12}>
            <Grid item md={6} xs={12}>
              <img src={imageAbout1} alt="about us" style={style.img}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={style.title}>
                Chwała zwycięzcy i nagroda zasłużona
              </Typography>
              <Typography sx={style.textJustify}>
                Wygrana w Mistrzostwach Szybkiego Barmana na KOSZALIN SHAKE OFF nie była tylko triumfem
                umiejętności i szybkości. Była to również celebracja pasji, poświęcenia i nieskończonej wiedzy na temat
                sztuki barmańskiej. Zwycięstwo w tak prestiżowej konkurencji to dowód na to, że barman jest w absolutnej
                czołówce swojego zawodu.
              </Typography>
              <Typography sx={style.textJustify}>
                Ale za takie osiągnięcie należy się też konkretne wyróżnienie. Jesteśmy dumni, że mogliśmy być częścią
                tej niezwykłej rywalizacji i wspierać ją nie tylko duchem, ale i nagrodą. Wygrana w konkursie była
                hojnie nagradzana przez nas, co tylko podkreślało wagę i znaczenie tego osiągnięcia. Dla zwycięzcy był
                to nie tylko symboliczny triumf, ale także konkretne docenienie jego umiejętności.
              </Typography>
              <Typography sx={style.textJustify}>
                Gratulujemy zwycięzcy i wszystkim uczestnikom! Wasza pasja do rzemiosła barmańskiego inspiruje i
                zachwyca. Dziękujemy za te emocje i niezapomniane chwile podczas KOSZALIN SHAKE OFF.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </SizeWindow>
    </NamePage>
  )
}

export default EventsPage
